<template>
  <div class="pin-pai-container">
    <div class="title"><vPageTitle></vPageTitle></div>
    <div class="item1">
      <vCard title="品牌销售额排行榜">
        <div class="chartContent" ref="item2">
          <div class="top-five-sales">
            <div class="content-title">
              <img src="../../assets/pinpai/icon-triangle@2x.png" alt="" />
              <span>TOP5品牌</span>
            </div>
            <div class="top-five-sales-content" ref="topFiveSales"></div>
          </div>
          <div class="top-ten-sales">
            <div class="content-title">
              <img src="../../assets/pinpai/icon-triangle@2x.png" alt="" />
              <span>TOP10品牌</span>
            </div>
            <div class="top-ten-sales-content" ref="topTenSales"></div>
          </div>
        </div>
      </vCard>
    </div>
    <div class="item2">
      <vCard title="品牌销量提升排行榜">
        <div class="chartContent">
          <div class="item2-top">
            <div class="content-title">
              <img src="../../assets/pinpai/icon-triangle@2x.png" alt="" />
              <span>TOP3提升度</span>
            </div>
            <div class="flex1 flex">
              <div class="item2-top-item item2-top-item1">
                <div class="chart" ref="item2Top1">chart</div>
                <div class="info">
                  <div class="value">
                    <img src="@/assets/pinpai/icon-up.png" alt="" />
                    {{ `${(item2Data.data1.value * 100).toFixed(1)}%` }}
                  </div>
                  <div class="name">
                    {{ item2Data.data1.name }}
                  </div>
                </div>
              </div>
              <div class="item2-top-item item2-top-item2">
                <div class="chart" ref="item2Top2">chart</div>
                <div class="info">
                  <div class="value">
                    <img src="@/assets/pinpai/icon-up.png" alt="" />
                    {{ `${(item2Data.data2.value * 100).toFixed(1)}%` }}
                  </div>
                  <div class="name">
                    {{ item2Data.data2.name }}
                  </div>
                </div>
              </div>
              <div class="item2-top-item item2-top-item3">
                <div class="chart" ref="item2Top3">chart</div>
                <div class="info">
                  <div class="value">
                    <img src="@/assets/pinpai/icon-up.png" alt="" />
                    {{ `${(item2Data.data3.value * 100).toFixed(1)}%` }}
                  </div>
                  <div class="name">
                    {{ item2Data.data3.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item2-bottom flex">
            <div class="content-title">
              <img src="../../assets/pinpai/icon-triangle@2x.png" alt="" />
              <span>TOP10提升度</span>
            </div>
            <div class="flex1" ref="item2Bottom"></div>
          </div>
        </div>
      </vCard>
    </div>
    <div class="item3">
      <SuppLier></SuppLier>
    </div>
    <div class="item4">
      <vCard title="主播带货榜">
        <div class="chartContent" ref="item4">
          <div class="top-three-anchor">
            <div class="content-title">
              <img src="../../assets/pinpai/icon-triangle@2x.png" alt="" />
              <span>TOP3主播</span>
            </div>
            <div class="top-three-anchor-content">
              <div class="anchor-content">
                <div class="anchor-content-bac">
                  <img
                    style="border-radius: 50%"
                    :src="sellGoodsTop2.avatar"
                    alt=""
                  />
                  <img src="../../assets/pinpai/top-two.png" alt="" />
                </div>
                <div class="anchor-content-sort">
                  <img src="../../assets/pinpai/top2.png" alt="" />
                </div>
                <div class="anchor-content-name">
                  {{ sellGoodsTop2.name }}
                  <span style="font-size: 12px; color: #999">
                    {{ sellGoodsTop2.value }}
                  </span>
                </div>
              </div>
              <div class="anchor-content">
                <div class="anchor-content-bac">
                  <img
                    style="border-radius: 50%"
                    :src="sellGoodsTop1.avatar"
                    alt=""
                  />
                  <img src="../../assets/pinpai/top-one.png" alt="" />
                </div>
                <div class="anchor-content-sort">
                  <img src="../../assets/pinpai/top1.png" alt="" />
                </div>
                <div class="anchor-content-name">
                  {{ sellGoodsTop1.name }}
                  <span style="font-size: 12px; color: #999">
                    {{ sellGoodsTop1.value }}
                  </span>
                </div>
              </div>
              <div class="anchor-content">
                <div class="anchor-content-bac">
                  <img
                    style="border-radius: 50%"
                    :src="sellGoodsTop3.avatar"
                    alt=""
                  />
                  <img src="../../assets/pinpai/top-three.png" alt="" />
                </div>
                <div class="anchor-content-sort">
                  <img src="../../assets/pinpai/top3.png" alt="" />
                </div>
                <div class="anchor-content-name">
                  {{ sellGoodsTop3.name }}
                  <span style="font-size: 12px; color: #999">
                    {{ sellGoodsTop3.value }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="top-eight-anchor">
            <div class="content-title">
              <img src="../../assets/pinpai/icon-triangle@2x.png" alt="" />
              <span>TOP8主播</span>
            </div>
            <div class="top-eight-anchor-content" ref="topTenAnchor"></div>
          </div>
        </div>
      </vCard>
    </div>
    <div class="item5">
      <vCard title="成交商品排名">
        <div class="chartContent">
          <div
            class="transacted-goods"
            v-for="(item, index) in dealRankingList"
            :key="index"
          >
            <div class="goods-sort">
              <img
                v-if="index < 3"
                src="../../assets/pinpai/topThree.png"
                alt=""
              />
              <img v-else src="../../assets/pinpai/topEight.png" alt="" />
              <span>{{ index + 1 }}</span>
            </div>
            <div class="goods-name">{{ item.name }}</div>
            <div class="progress-bar">
              <div class="progress-bar-content">
                <div
                  class="progress-bar-con"
                  :style="{ width: item.width + '%' }"
                ></div>
                <div class="progress-bar-head"></div>
              </div>
            </div>
            <div class="progress-bar-schedule">{{ item.value }}</div>
          </div>
        </div>
      </vCard>
    </div>
  </div>
</template>
<script>
import vPageTitle from "@/components/pageTitle.vue";
import vCard from "@/components/card.vue";
import SuppLier from "./supplier.vue";
import * as echarts from "echarts";
import "echarts-liquidfill";
import "echarts-wordcloud";
import noSix from "@/assets/pinpai/no.6@2x.png";
import noSeven from "@/assets/pinpai/no.7@2x.png";
import noEight from "@/assets/pinpai/no.8@2x.png";
import noNine from "@/assets/pinpai/no.9@2x.png";
import noTen from "@/assets/pinpai/no.10@2x.png";
export default {
  name: "pinPai",
  data() {
    return {
      topFiveSales: null,
      topTenSales: null,
      topTenAnchor: null,
      sellGoodsTop1: {}, //主播带货第一名
      sellGoodsTop2: {}, //主播带货第二名
      sellGoodsTop3: {}, //主播带货第三名
      dealRankingList: [], //成交商品排名列表
      item2Data: { data1: {}, data2: {}, data3: {} },
      item21: null,
      item22: null,
      item23: null,
      item2Btm: null,
      item2BtmData: [],
    };
  },
  components: {
    vPageTitle,
    vCard,
    SuppLier,
  },
  mounted() {
    this.brandMarket();
    this.brandSales();
    this.renderItem2Bottom();
    this.sellGoodsAnchor();
    this.transactionRank();
    window.addEventListener("resize", this.resizeChart);
  },
  beforeDestroy() {
    this.topFiveSales && this.topFiveSales.dispose();
    this.topTenSales && this.topTenSales.dispose();
    this.topTenAnchor && this.topTenAnchor.dispose();
    this.item21 && this.item21.dispose();
    this.item22 && this.item22.dispose();
    this.item23 && this.item23.dispose();
    this.item2Btm && this.item2Btm.dispose();
    window.removeEventListener("resize", this.resizeChart);
  },
  methods: {
    // 品牌销售排行榜
    async brandMarket() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/ppxsephb"
      );
      const top5 = data.top5;
      const top10 = data.top10;
      // console.log("品牌销售排行榜数据：", data);
      this.topFiveSales = echarts.init(this.$refs.topFiveSales);
      this.topFiveSales.setOption({
        // title: {
        //   text: "TOP5品牌",
        //   textStyle: {
        //     color: "#bd4de5", //颜色
        //     fontStyle: "normal", //风格
        //     fontWeight: "bold", //粗细
        //     fontSize: 12, //大小
        //     align: "center", //水平对齐
        //   },
        // },
        grid: {
          left: "2%",
          right: "0%",
          top: "30",
          bottom: "2%",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          formatter: function (parms) {
            return parms.name + "：" + parms.value + "亿";
          },
        },
        xAxis: {
          type: "category", // category(坐标轴类型)
          data: top5.map((item) => item.name),
          axisTick: {
            // 坐标轴刻度相关配置
            show: false, // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            lineStyle: {
              // 坐标轴轴线样式
              color: "rgba(255,255,255,0.15)", // 坐标轴轴线颜色
            },
          },
          axisLabel: {
            interval: 0,
            color: "#ffffff",
            fontSize: 14,
          },
        },
        yAxis: {
          type: "value", // value(数值轴,适用于连续数据)
          name: "(亿)",
          axisTick: {
            // 坐标轴刻度相关配置
            show: false, // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            show: false, // 是否显示坐标轴轴线
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: "#ffffff",
            fontSize: 14,
          },
          splitLine: {
            // 坐标轴在 grid 区域中的分隔线
            lineStyle: {
              // 分割线配置
              color: "rgba(255,255,255,0.15)", // 分割线颜色
            },
          },
        },
        series: [
          // 底部的椭圆形(象形柱图):pictorialBar
          {
            type: "pictorialBar", // pictorialBar(象形柱图)
            label: {
              // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
              show: true, //是否显示标签
              position: ["17", "-30"], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
              color: "#01E4FF",
              fontSize: 14,
            },
            symbolSize: [29, 10], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
            symbolOffset: [0, 6], // 图形相对于原本位置的偏移
            z: 12, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
            itemStyle: {
              // 图形样式
              // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              color: function (params) {
                var colorList = [
                  "#EDA500",
                  "#0098F9",
                  "#FF8665",
                  "#F80075",
                  "#CA4BFF",
                ];
                return colorList[params.dataIndex];
              },
            },
            // data: top5.map((item) => item.value),
          },
          // 中间的长方形柱状图(柱状图):bar
          {
            type: "bar", // 柱状图
            barWidth: 29, // 柱条的宽度,不设时自适应
            barGap: "0%", // 柱子与柱子之间的距离
            itemStyle: {
              // 图形样式
              // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
              // 下面就是使用线性渐变
              color: function (params) {
                var colorList = [
                  ["rgba(53,42,52)", "rgba(222,154,0)"],
                  ["rgba(66,170,242,0)", "rgba(27, 110, 233, 1)"],
                  ["rgba(253,154,133,0)", "rgba(249, 93, 88, 1)"],
                  ["rgba(249, 88, 191, 0)", "rgba(222, 34, 100, 1)"],
                  ["rgba(204, 111, 248, 0)", "rgba(181, 77, 246, 1)"],
                ];
                var colorItem = colorList[params.dataIndex];
                return new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: colorItem[0],
                    },
                    {
                      offset: 1,
                      color: colorItem[1],
                    },
                  ],
                  false
                );
              },
            },
            data: top5.map((item) => item.value),
          },
          // 顶部的椭圆形(象形柱图):pictorialBar
          {
            type: "pictorialBar",
            symbolSize: [29, 10],
            symbolOffset: [0, -6],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              color: function (params) {
                var colorList = [
                  "#B57F00",
                  "#006DC0",
                  "#C8564E",
                  "#C31B79",
                  "#963AC7",
                ];
                return colorList[params.dataIndex];
              },
            },
            data: top5.map((item) => item.value),
          },
        ],
      });
      this.topTenSales = echarts.init(this.$refs.topTenSales);
      this.topTenSales.setOption({
        // title: {
        //   show: false,
        //   text: "TOP10品牌",
        //   textStyle: {
        //     color: "#bd4de5", //颜色
        //     fontStyle: "normal", //风格
        //     fontWeight: "bold", //粗细
        //     fontSize: 12, //大小
        //     align: "center", //水平对齐
        //   },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            return params[0]?.value + "亿";
          },
        },
        grid: {
          top: "1%",
          left: "1%",
          right: "8%",
          bottom: "1%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          // axisLabel: {
          // formatter: "{value}",
          // show: true,
          // },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          inverse: true, // 倒叙
          axisLabel: {
            interval: 0,
            color: "#ffffff",
            fontSize: "14px",
            // margin: 100, // 距离右侧图形距离，配合axisLabel.left 和 grid.left 使用
            // 必须使用formatter，配合rich使用
            formatter: (params, index) => {
              // return [`{a${index + 1}|${params}}`].join("\n"); //暂时去除前面的图片
              return `${params}`;
            },
            align: "right", // 文字左排序
            rich: {
              a1: {
                color: "#fff",
                backgroundColor: {
                  image: noSix,
                  width: 169,
                  height: 14,
                },
                align: "center",
                borderRadius: 4,
              },
              a2: {
                color: "#fff",
                backgroundColor: {
                  image: noSeven,
                  width: 169,
                  height: 14,
                },
                align: "center",
                borderRadius: 4,
              },
              a3: {
                color: "#fff",
                backgroundColor: {
                  image: noEight,
                  width: 169,
                  height: 14,
                },
                align: "center",
                borderRadius: 4,
              },
              a4: {
                color: "#fff",
                backgroundColor: {
                  image: noNine,
                  width: 169,
                  height: 14,
                },
                align: "center",
                borderRadius: 4,
              },
              a5: {
                color: "#fff",
                backgroundColor: {
                  image: noTen,
                  width: 169,
                  height: 14,
                },
                align: "center",
                borderRadius: 4,
              },
            },
          },
          axisLine: {
            show: false, // 轴线
          },
          axisTick: {
            show: false, // 刻度线
          },
          data: top10.map((item) => item.name),
        },
        series: [
          {
            type: "bar",
            barWidth: 5,
            barMaxWidth: 20,
            barMinWidth: 5,
            label: {
              show: true,
              distance: 20,
              position: "right",
              color: "#fff",
              fontStyle: "normal",
              fontWeight: "400",
              formatter: (params) => {
                return params.value + "亿";
              },
            },
            itemStyle: {
              borderRadius: [10, 10, 10, 10],
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "rgba(15, 134, 235, 1)" },
                { offset: 1, color: "rgba(35, 255, 226, 1)" },
              ]),
              borderWidth: 1,
              borderColor: "black",
            },
            data: top10.map((item) => item.value),
          },
        ],
      });
    },
    // 品牌销量提升排行榜
    async brandSales() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/ppxltsphb/top3"
      );
      this.item2Data = data;
      this.brandSales1(this.item2Data.data1.value);
      this.brandSales2(this.item2Data.data2.value);
      this.brandSales3(this.item2Data.data3.value);
    },
    brandSales1(data) {
      this.item21 = echarts.init(this.$refs.item2Top1);
      this.item21.setOption({
        series: [
          {
            label: { show: false },
            type: "liquidFill",
            radius: "97%",
            center: ["50%", "50%"],
            data: [data, data - 0.1],
            backgroundStyle: {
              color: "transparent",
            },
            outline: {
              borderDistance: 5,
              itemStyle: {
                borderWidth: 3,
                borderColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(192,189,42,0)",
                    },
                    {
                      offset: 0.5,
                      color: "rgba(192,189,42,0.7)",
                    },
                    {
                      offset: 1,
                      color: "rgba(192,189,42,1)",
                    },
                  ],
                  globalCoord: false,
                },
              },
            },
            color: [
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: "#badb05",
                  },
                  {
                    offset: 0,
                    color: "#e7a83b",
                  },
                ],
              },
            ],
          },
        ],
      });
    },
    brandSales2(data) {
      this.item22 = echarts.init(this.$refs.item2Top2);
      this.item22.setOption({
        series: [
          {
            label: { show: false },
            type: "liquidFill",
            radius: "97%",
            center: ["50%", "50%"],
            data: [data, data - 0.1],
            backgroundStyle: {
              color: "transparent",
            },
            outline: {
              borderDistance: 5,
              itemStyle: {
                borderWidth: 3,
                borderColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0,212,252,0)",
                    },
                    {
                      offset: 0.5,
                      color: "rgba(0,212,252,0.7)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,212,252,1)",
                    },
                  ],
                  globalCoord: false,
                },
              },
            },
            color: [
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: "#00d4fc",
                  },
                  {
                    offset: 0,
                    color: "#00ffb6",
                  },
                ],
              },
            ],
          },
        ],
      });
    },
    brandSales3(data) {
      this.item23 = echarts.init(this.$refs.item2Top3);
      this.item23.setOption({
        series: [
          {
            label: { show: false },
            type: "liquidFill",
            radius: "97%",
            center: ["50%", "50%"],
            data: [data, data - 0.1],
            backgroundStyle: {
              color: "transparent",
            },
            outline: {
              borderDistance: 5,
              itemStyle: {
                borderWidth: 3,
                borderColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0,122,255,0)",
                    },
                    {
                      offset: 0.5,
                      color: "rgba(0,122,255,0.7)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,122,255,1)",
                    },
                  ],
                  globalCoord: false,
                },
              },
            },
            color: [
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: "#0079ff",
                  },
                  {
                    offset: 0,
                    color: "#00eafe",
                  },
                ],
              },
            ],
          },
        ],
      });
    },
    async renderItem2Bottom() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/ppxltsphb/top10"
      );
      this.item2BtmData = data;
      this.item2Btm = echarts.init(this.$refs.item2Bottom);
      this.item2Btm.setOption({
        grid: {
          top: "20%",
          bottom: "2%",
          right: "2%",
          left: "2%",
          containLabel: true,
        },
        xAxis: {
          data: this.item2BtmData.map((item) => item.name),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#26274f",
            },
          },
          axisLabel: {
            show: true,
            interval: 0,
            textStyle: {
              color: "#fff",
              fontSize: 12,
            },
          },
        },
        yAxis: {
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#26274f",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            type: "pictorialBar",
            symbol:
              "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==",
            symbolSize: [20, 20],
            symbolOffset: [0, -10],
            z: 12,
            data: this.item2BtmData.map((item) => ({
              value: item.value,
              symbolPosition: "end",
            })),
          },
          {
            name: "条",
            type: "bar",
            data: this.item2BtmData,
            barWidth: 4,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 1,
                    color: "rgba(2,158,217,0)",
                  },
                  {
                    offset: 0,
                    color: "#029ED9",
                  },
                ]),
              },
            },
            label: {
              color: "#fff",
              show: true,
              position: "top",
              // offset: [30, 0],
              textStyle: {
                fontSize: 14,
                fontWeight: 600,
                color: "#00e489",
              },
            },
          },
          {
            type: "pictorialBar",
            symbol:
              "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==",
            symbolSize: [8, 8],
            symbolOffset: [0, 0],
            z: 12,
            data: this.item2BtmData.map((item) => ({
              value: item.value,
              symbolPosition: "start",
            })),
          },
        ],
      });
    },
    // 主播带货榜
    async sellGoodsAnchor() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/zbdhb"
      );
      this.sellGoodsTop1 = data.top1;
      this.sellGoodsTop2 = data.top2;
      this.sellGoodsTop3 = data.top3;
      const other = data.other;
      // console.log("主播带货榜数据：", data);
      this.topTenAnchor = echarts.init(this.$refs.topTenAnchor);
      this.topTenAnchor.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (arr) => {
            return `${arr[0].value}w`;
          },
        },
        grid: {
          top: "1%",
          left: "-10%",
          right: "16%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          inverse: true, // 倒叙
          axisLabel: {
            margin: 80,
            align: "left", // 文字左排序
            color: "#BFF7FF",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 12,
            formatter: (val) => {
              return `${val}`;
            },
          },
          axisLine: {
            show: false, // 轴线
          },
          axisTick: {
            show: false, // 刻度线
          },
          data: other.map((item) => item.name),
        },
        series: [
          {
            type: "bar",
            barWidth: 6,
            barMaxWidth: 20,
            barMinWidth: 5,
            label: {
              normal: {
                show: true,
                distance: 20,
                position: "right",
                color: "#fff",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: 12,
                formatter: (params) => {
                  return params.value + "w";
                },
              },
            },
            itemStyle: {
              borderRadius: [2, 2, 2, 2],
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "rgba(0, 118, 255, 1)" },
                { offset: 1, color: "rgba(14, 215, 238, 1)" },
              ]),
              borderWidth: 1,
              borderColor: "black",
            },
            data: other.map((item) => item.value),
          },
        ],
      });
    },
    // 成交商品排名
    async transactionRank() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/cjsppm"
      );
      this.dealRankingList = data.slice(0, 8); //取前八名
      // console.log("成交商品排名：", data);
      //#region 成交商品排名，暂时不用echarts
      // echarts.init(this.$refs.item5).setOption({
      //   tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       type: "shadow",
      //     },
      //   },
      //   grid: {
      //     top: "1%",
      //     left: "-35%",
      //     right: "1%",
      //     bottom: "1%",
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     show: false,
      //     axisLine: {
      //       show: false,
      //     },
      //   },
      //   yAxis: {
      //     type: "category",
      //     inverse: true,
      //     axisLabel: {
      //       margin: 130,
      //       formatter: (params, index) => {
      //         return [` {a${index + 1}|${index + 1}}   ${params}`].join("\n");
      //       },
      //       align: "left",
      //       rich: {
      //         a1: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topThree,
      //             width: 13,
      //             height: 14,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //         a2: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topThree,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //         a3: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topThree,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //         a4: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topEight,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //         a5: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topEight,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //         a6: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topEight,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //         a7: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topEight,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //         a8: {
      //           color: "#fff",
      //           backgroundColor: {
      //             image: topEight,
      //           },
      //           width: 18,
      //           height: 18,
      //           align: "center",
      //           borderRadius: 4,
      //         },
      //       },
      //     },
      //     axisLine: {
      //       show: false,
      //     },
      //     axisTick: {
      //       show: false,
      //     },
      //     data: [
      //       "ipad pro",
      //       "IPhione12Promax",
      //       "IPhione12",
      //       "IPhione12PRO",
      //       "ipad pro",
      //       "IPhione12Promax",
      //       "IPhione12",
      //       "IPhione12PRO",
      //     ],
      //   },
      //   series: [
      //     {
      //       type: "bar",
      //       barWidth: 5,
      //       barMaxWidth: 20,
      //       barMinWidth: 5,
      //       label: {
      //         normal: {
      //           show: true,
      //           distance: 20,
      //           position: "right",
      //           color: "#fff",
      //           fontStyle: "normal",
      //           fontWeight: "400",
      //           fontSize: 9,
      //         },
      //       },
      //       itemStyle: {
      //         borderRadius: [10, 10, 10, 10],
      //         color: "rgba(57, 255, 220, 1)",
      //         borderWidth: 1,
      //         borderColor: "black",
      //       },
      //       data: [120, 201, 303, 420, 503, 201, 303, 420],
      //     },
      //   ],
      // });
      //#endregion
    },
    resizeChart() {
      this.topFiveSales && this.topFiveSales.resize();
      this.topTenSales && this.topTenSales.resize();
      this.topTenAnchor && this.topTenAnchor.resize();
      this.item21 && this.item21.resize();
      this.item22 && this.item22.resize();
      this.item23 && this.item23.resize();
      this.item2Btm && this.item2Btm.resize();
    },
  },
};
</script>
<style scoped lang="scss">
.pin-pai-container {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/pinpai/pinpaiBg.png");
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
  gap: 10px 0;
  grid-auto-flow: row;
  grid-template-areas:
    "title title title title title title title"
    "item1 item1 item3 item3 item3 item4 item4"
    "item1 item1 item3 item3 item3 item4 item4"
    "item1 item1 item3 item3 item3 item4 item4"
    "item1 item1 item3 item3 item3 item4 item4"
    "item2 item2 item3 item3 item3 item5 item5"
    "item2 item2 item3 item3 item3 item5 item5"
    "item2 item2 item3 item3 item3 item5 item5"
    "item2 item2 item3 item3 item3 item5 item5";
}
.title {
  grid-area: title;
}
.chartContent {
  width: 100%;
  height: 100%;
}
.content-title {
  height: 16px;
  display: flex;
  align-items: center;
  img {
    width: 8px;
    height: 12px;
  }
  span {
    margin-left: 4px;
    font-size: 9px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #bd4de5;
  }
}

.item1 {
  grid-area: item1;

  .chartContent {
    display: flex;
    flex-direction: column;
    .top-five-sales {
      flex: 2.75;
      &-content {
        width: 100%;
        height: 100%;
      }
    }
    .top-ten-sales {
      flex: 2.25;
      .content-title {
        margin-top: 10px;
      }
      &-content {
        width: 100%;
        height: 100%;
        margin-top: 10px;
      }
    }
  }
}

.item2 {
  grid-area: item2;
  .chartContent {
    display: flex;
    flex-direction: column;
    .item2-top,
    .item2-bottom {
      flex: 1;
    }
  }
  .item2-top {
    display: flex;
    flex-direction: column;
    .content-title {
      margin: 10px 0;
    }
    .item2-top-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      .chart {
        flex: 1;
      }
      .info {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .value {
          text-align: center;
          font-weight: bold;
          img {
            width: 10px;
            height: 15px;
            margin-right: 5px;
          }
        }
        .name {
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .item2-top-item1 {
      background: url("~@/assets/pinpai/item2Bg1.png");
      background-size: 100% 100%;
    }
    .item2-top-item2 {
      background: url("~@/assets/pinpai/item2Bg2.png");
      background-size: 100% 100%;
    }
    .item2-top-item3 {
      background: url("~@/assets/pinpai/item2Bg3.png");
      background-size: 100% 100%;
    }
  }
  .item2-bottom {
    flex-direction: column;
    margin-top: 10px;
  }
}

.item3 {
  grid-area: item3;
}

.item4 {
  grid-area: item4;
  .chartContent {
    display: flex;
    flex-direction: column;
    .top-three-anchor {
      flex: 1;
      margin-bottom: -10px;
      &-content {
        margin-top: 5px;
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        .anchor-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-sort {
            width: 63px;
            height: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &-name {
            font-size: 9px;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .anchor-content:nth-child(1) {
          .anchor-content {
            &-bac {
              width: 107px;
              height: 107px;
              position: relative;
              img:nth-child(1) {
                width: 78px;
                height: 78px;
                position: absolute;
                top: 15px;
                left: 14px;
              }
              img:nth-child(2) {
                width: 107px;
                height: 107px;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
        .anchor-content:nth-child(2) {
          .anchor-content {
            &-bac {
              width: 122px;
              height: 122px;
              position: relative;
              img:nth-child(1) {
                width: 91px;
                height: 91px;
                position: absolute;
                top: 16px;
                left: 18px;
              }
              img:nth-child(2) {
                width: 122px;
                height: 122px;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
        .anchor-content:nth-child(3) {
          .anchor-content {
            &-bac {
              width: 91px;
              height: 91px;
              position: relative;
              img:nth-child(1) {
                width: 67px;
                height: 67px;
                position: absolute;
                top: 13px;
                left: 11px;
              }
              img:nth-child(2) {
                width: 91px;
                height: 91px;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
    .top-eight-anchor {
      flex: 1;
      .content-title {
        margin-top: 5px;
      }
      &-content {
        margin-top: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.item5 {
  grid-area: item5;
  .chartContent {
    display: flex;
    flex-direction: column;
    .transacted-goods {
      flex: 1;
      width: 100%;
      // margin-top: 5px;
      // padding-bottom: 5px;
      background: url("~@/assets/pinpai/bg-top.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      .goods-sort {
        display: flex;
        margin-left: 10px;
        // align-items: center;
        img {
          width: 18px;
          height: 18px;
        }
        span {
          margin-left: -12px;
          margin-top: 5px;
          font-size: 8px;
          font-family: DIN;
          font-weight: bold;
          color: #ffffff;
          line-height: 6px;
        }
      }
      .goods-name {
        width: 130px;
        margin-left: 10px;
        font-size: 7px;
        font-weight: 400;
        color: #ffffff;
        line-height: 31px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .progress-bar {
        width: 150px;
        // margin-left: 10px;
        &-content {
          width: 100%;
          height: 6px;
          display: flex;
          align-items: center;
          // background: #ffffff;
          // opacity: 0.1;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 2px;
          .progress-bar-con {
            // width: 85%;
            height: 6px;
            background: linear-gradient(to top right, #59f05d, #39ffdc);
            // background: linear-gradient(90deg, #59f05d 100%);
            box-shadow: 0px 0px 7px 0px #39ffdc;
            border-radius: 2px;
          }
          .progress-bar-head {
            width: 4px;
            height: 4px;
            background: #ffffff;
            border-radius: 50%;
          }
        }
      }
      .progress-bar-schedule {
        margin-left: 15px;
        font-size: 14px;
      }
    }
  }
}
</style>
