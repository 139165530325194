<template>
  <div class="zhiBo-container">
    <div class="title">
      <vPageTitle></vPageTitle>
    </div>
    <div class="zhibo-content">
      <div class="sub-title flex-center">
        <div class="text">直播园区直播同步画面</div>
      </div>
      <div
        class="flex1 content"
        id="zhiBoContent"
        :style="{ overflowY: zhiboData.disabledScroll ? 'hidden' : 'auto' }"
      >
        <!-- 前6名 -->
        <div class="flex top6">
          <!-- 每一个单卡 -->
          <div
            v-for="(item, index) in zhiboData.top6"
            :key="index"
            class="card"
            @mouseenter="handleMouseEnter(item)"
            @mouseleave="handleMouseLeave(item)"
            @click="toRoom(item)"
          >
            <!-- 封面 -->
            <div class="item-logo">
              <div class="logo-img-wrapper">
                <img class="logo-img" :src="item.roomImg" alt="" />
              </div>
              <div class="address">
                <img
                  class="address-icon"
                  src="@/assets/zhibo/icon-position.png"
                  alt=""
                />
                <span class="address-text">{{ item.address }}</span>
              </div>
              <div class="goLiveRoom flex-center" v-show="item.showEnter">
                <div class="text flex-center">进入直播大屏</div>
              </div>
              <!-- 前三icon -->
              <div class="top3">
                <img
                  v-if="index === 0"
                  src="@/assets/zhibo/icon-top 1.png"
                  alt=""
                />
                <img
                  v-if="index === 1"
                  src="@/assets/zhibo/icon-top 2.png"
                  alt=""
                />
                <img
                  v-if="index === 2"
                  src="@/assets/zhibo/icon-top 3.png"
                  alt=""
                />
              </div>
            </div>
            <!-- 详情数据 -->
            <div class="item-content flex1">
              <div class="flex item-content-header">
                <div class="avatar">
                  <!-- top3头像徽章 -->
                  <img
                    v-if="index === 0"
                    class="avatar-top"
                    src="@/assets/zhibo/top1.png"
                    alt=""
                  />
                  <img
                    v-if="index === 1"
                    class="avatar-top"
                    src="@/assets/zhibo/top2.png"
                    alt=""
                  />
                  <img
                    v-if="index === 2"
                    class="avatar-top"
                    src="@/assets/zhibo/top3.png"
                    alt=""
                  />
                  <img class="avatar-img" :src="item.avatar" alt="" />
                </div>
                <div class="item-content-header-info">
                  <div class="item-name">{{ item.name }}</div>
                  <div class="item-data">
                    <span class="flex-center" style="white-space: nowrap">
                      <img
                        class="fans-img"
                        src="@/assets/zhibo/icon-fans.png"
                        alt=""
                      />
                      {{ item.fansNum }}粉丝
                    </span>
                    <span class="flex-center">
                      <img src="@/assets/zhibo/icon-id.png" alt="" />
                      ID:{{ item.id }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex user-info">
                <div
                  v-for="(i, ix) in item.userData"
                  :key="i.name"
                  :style="{ width: ix % 2 === 1 ? '40%' : '60%' }"
                >
                  <div class="user-info-name">{{ i.name }}</div>
                  <div class="user-info-value">{{ i.value }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 前六以后 -->
        <div class="flex top6Other">
          <div
            v-for="(item, index) in zhiboData.other"
            :key="index"
            class="card"
            @click="toRoom(item)"
            @mouseenter="handleMouseEnter(item)"
            @mouseleave="handleMouseLeave(item)"
          >
            <div class="item-logo">
              <div class="logo-img-wrapper">
                <img class="logo-img" :src="item.roomImg" alt="" />
              </div>
              <div class="address">
                <img
                  class="address-icon"
                  src="@/assets/zhibo/icon-position.png"
                  alt=""
                />
                <span class="address-text">{{ item.address }}</span>
              </div>
              <div class="goLiveRoom flex-center" v-show="item.showEnter">
                <div class="text flex-center">进入直播大屏</div>
              </div>
            </div>
            <div class="item-content flex1">
              <div class="flex item-content-header">
                <div class="avatar"><img :src="item.avatar" alt="" /></div>
                <div class="item-content-header-info">
                  <div class="item-name">{{ item.name }}</div>
                  <div class="item-data">
                    <span class="flex-center" style="white-space: nowrap">
                      <img
                        class="fans-img"
                        src="@/assets/zhibo/icon-fans.png"
                        alt=""
                      />
                      {{ item.fansNum }}粉丝
                    </span>
                    <span class="flex-center">
                      <img src="@/assets/zhibo/icon-id.png" alt="" />
                      ID:{{ item.id }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="video-wrapper" v-if="currentVideo" @click="currentVideo = ''">
        <div class="video-box">
          <video class="video" :src="currentVideo" autoplay></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import vPageTitle from "@/components/pageTitle.vue";
export default defineComponent({
  name: "zhiBo",
  components: { vPageTitle },
  data() {
    return {
      zhiboData: {},
      currentVideo: "",
    };
  },
  created() {
    this.$axios("https://mock.apifox.cn/m1/2727236-0-default/live").then(
      (res) => {
        this.zhiboData = res.data;
      }
    );
  },
  methods: {
    toRoom(item) {
      this.currentVideo = item.videoUrl;
      console.log(this.currentVideo);
    },
    handleMouseEnter(item) {
      this.$set(item, "showEnter", true);
    },
    handleMouseLeave(item) {
      this.$set(item, "showEnter", false);
    },
  },
});
</script>
<style scoped lang="scss">
.zhiBo-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #1e0732 url("~@/assets/bg-grid.png");
  background-size: 100% 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.3fr;
  gap: 10px 0;
  grid-auto-flow: row;
  grid-template-areas:
    "title title title title title title title"
    "content content content content content content content"
    "content content content content content content content"
    "content content content content content content content"
    "content content content content content content content"
    "content content content content content content content"
    "content content content content content content content"
    "content content content content content content content"
    "content content content content content content content";
}
.title {
  grid-area: title;
}
.zhibo-content {
  grid-area: content;
  background: rgba(40, 33, 80, 0.45);
  margin: 30px;
  display: flex;
  flex-direction: column;
  .sub-title {
    width: 50%;
    height: 50px;
    margin: 0 auto;
    background: url("~@/assets/zhibo/subTitle.png");
    background-size: 100% 100%;
    .text {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 5px;
      background-image: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1),
        rgba(229, 159, 220, 1)
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;
  }

  .top6 {
    height: 60%;
    margin-bottom: 10px;
    justify-content: space-between;
    .item-logo {
      width: 100%;
      height: 62%;
      position: relative;
    }
    .item-content-header {
      padding: 5px 0;
      margin-bottom: 10px;
      height: 27%;
      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 20px;
        position: relative;
        .avatar-img {
          position: absolute;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          z-index: 5;
        }
        .avatar-top {
          z-index: 4;
          position: absolute;
          width: 62px;
          height: 62px;
          left: -10px;
          top: -11px;
        }
      }
      .item-content-header-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      }
      .item-name {
        font-size: 13px;
      }
      .item-data {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ccc;
        .fans-img {
          width: 19px;
        }
        img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }
    }
    .user-info {
      height: 70%;
      flex-wrap: wrap;
      justify-content: space-between;
      .user-info-name {
        font-size: 12px;
      }
      .user-info-value {
        margin-top: 4px;
        font-size: 12px;
        color: #2fc6dc;
      }
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin: 0 0.5% 10px 0.5%;
    width: 15%;
    height: 100%;
    background: url("~@/assets/zhibo/cardBg.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .logo-img-wrapper {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .logo-img {
      object-fit: cover;
      width: 100%;
      min-height: 100%;
    }
  }
  .address {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    padding: 0 0 10px 10px;
    display: flex;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    .address-icon {
      width: 13px;
      height: 15px;
      margin-right: 10px;
    }
    .address-text {
      font-size: 13px;
      color: #ffffff;
    }
  }
  .top6Other {
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      height: 315px;
      .item-logo {
        width: 100%;
        height: 85%;
        position: relative;
      }
    }
    .item-content-header {
      padding: 5px 0;
      margin-bottom: 10px;
      height: 100%;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 20px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-content-header-info {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .item-name {
        font-size: 13px;
        margin-bottom: 6px;
      }
      .item-data {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #ccc;
        .fans-img {
          width: 19px;
        }
        img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }
    }
  }
  .goLiveRoom {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 162px;
      height: 49px;
      background-image: url("~@/assets/zhibo/enter.png");
      background-size: 100% 100%;
    }
  }
}
.top3 {
  position: absolute;
  top: -12px;
  left: 0;
  width: 51px;
  height: 86px;
  z-index: 3;
  img {
    height: 100%;
    width: 100%;
  }
}
::-webkit-scrollbar {
  width: 0;
}
.video-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  .video-box {
    padding: 15px 10px 15px 10px;
    margin: 5vh auto 0;
    width: 470px;
    height: 700px;
    background: url("~@/assets/zhibo/videobg.png") no-repeat;
    background-size: 100% 100%;
    .video {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
