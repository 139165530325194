<template>
  <div class="wuLiu-container">
    <div class="title">
      <vPageTitle></vPageTitle>
    </div>
    <div class="leftTitle topTitle">
      <div class="text">易心直播物流发货地</div>
    </div>
    <!-- 占位-->
    <div class="centerTitle"></div>
    <div class="rightTitle topTitle">
      <div class="text">禹种不同物流发货地</div>
    </div>
    <div class="item1">
      <vCard
        title="主要发货地数据"
        :tabs="monthAndYear"
        :currentTab="currentTab1"
        @changeTab="changeItem1Tab"
      >
        <div class="chartContent" ref="item1"></div>
      </vCard>
    </div>
    <div class="item2">
      <vCard
        title="主要收货地排行"
        :tabs="monthAndYear"
        :current-tab="currentTab2"
        @changeTab="changeItem2Tab"
      >
        <div class="chartContent" ref="item2">
          <div class="topChart" ref="topChart"></div>
          <div class="btmChart" ref="btmChart"></div>
        </div>
      </vCard>
    </div>
    <div class="item3">
      <div class="item3-title">
        <div class="title">园区物流发货地信息</div>
      </div>
      <div class="map" ref="item3"></div>
      <div class="dataInfo">
        <div class="title">园区收发货数据详情</div>
        <div class="info">
          <div v-for="(item, index) in item32Data" :key="index">
            <div class="info-name">
              <img
                style="height: 20px; width: 20px"
                :src="require('@/assets/wuliu/' + item.icon + '.png')"
                alt=""
              />
              {{ item.name }}
            </div>
            <div class="info-value">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item4">
      <vCard
        title="主要发货地数据"
        :tabs="monthAndYear"
        @changeTab="changeItem4Tab"
        :current-tab="currentTab4"
      >
        <div class="chartContent" ref="item4"></div>
      </vCard>
    </div>
    <div class="item5">
      <vCard
        title="主要收货地排行"
        :tabs="item5Tabs"
        :current-tab="currentTab5"
        @changeTab="changeItem5Tab"
      >
        <div class="chartContent" style="display: flex; flex-direction: column">
          <div class="item5-top">
            <div class="left">
              <div class="text">发货数据</div>
            </div>
            <div style="width: 16%"></div>
            <div class="right">
              <div class="text">收货数据</div>
            </div>
          </div>
          <div style="flex: 1">
            <div class="chartContent" ref="item5"></div>
          </div>
          <div class="item5-bottom">
            <div class="item5-bottom-item">
              <div class="icon icon1"></div>
              {{ item5Data.yData.left.data.name }}
            </div>
            <div class="item5-bottom-item">
              <div class="icon icon2"></div>
              {{ item5Data.yData.left.userData.name }}
            </div>
            <div class="item5-bottom-item">
              <div class="icon icon3"></div>
              {{ item5Data.yData.right.data.name }}
            </div>
            <div class="item5-bottom-item">
              <div class="icon icon4"></div>
              {{ item5Data.yData.right.userData.name }}
            </div>
          </div>
        </div>
      </vCard>
    </div>
  </div>
</template>
<script>
import vPageTitle from "@/components/pageTitle.vue";
import vCard from "@/components/card.vue";
import * as echarts from "echarts";
import china from "@/views/home/china.json";
import item1Bg from "@/assets/wuliu/item1-bg.png";
export default {
  name: "wuLiu",
  components: { vPageTitle, vCard },
  data() {
    return {
      item1: null,
      item1Data: [],
      currentTab1: "month",
      monthAndYear: [
        { name: "单月数据量", code: "month" },
        { name: "年数据量", code: "year" },
      ],
      currentTab2: "month",
      topChart: null,
      btmChart: null,
      topChartData: [],
      btmChartData: {},
      item3: null,
      item3Data: [],
      item4: null,
      item4Data: {},
      currentTab4: "month",
      mTime: "", // 用定时器控制高亮
      dataLength: china.features.length,
      index: -1,
      geoCoordMap: {},

      item32Data: [],
      item5: null,
      item5Data: {
        xData: [],
        yData: {
          left: {
            data: { name: "发货数据", value: [] },
            userData: { name: "活跃用户", value: [] },
          },
          right: {
            data: { name: "收货数据", value: [] },
            userData: { name: "活跃用户", value: [] },
          },
        },
      },
      currentTab5: "top4",
      item5Tabs: [
        { name: "前4位城市", code: "top4" },
        { name: "综合排名", code: "other" },
      ],
    };
  },
  mounted() {
    this.renderItem1();
    this.renderItem2Top();
    this.renderItem2Btm();
    this.initGeoMap();
    this.renderItem3();
    this.renderItem32();
    this.renderItem4();
    this.renderItem5();
    window.addEventListener("resize", this.resizeChart);
  },
  beforeDestroy() {
    this.item1 && this.item1.dispose();
    this.topChart && this.topChart.dispose();
    this.btmChart && this.btmChart.dispose();
    this.item3 && this.item3.dispose();
    this.item4 && this.item4.dispose();
    this.item5 && this.item5.dispose();
    clearInterval(this.mTime);
    window.removeEventListener("resize", this.resizeChart);
  },
  methods: {
    changeItem1Tab(code) {
      this.currentTab1 = code;
      this.renderItem1(false);
    },
    async renderItem1(init = true) {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m2/2727236-0-default/82538828"
      );
      this.item1Data = data[this.currentTab1];
      // 日均
      const averageNum = data[`${this.currentTab1}AverageNum`];
      // 预期
      const expectNum = data[`${this.currentTab1}ExpectNum`];
      // 绘制左侧面
      if (init) {
        const CubeLeft = echarts.graphic.extendShape({
          shape: {
            x: 0,
            y: 0,
          },
          buildPath: function (ctx, shape) {
            // 会canvas的应该都能看得懂，shape是从custom传入的
            const xAxisPoint = shape.xAxisPoint;
            const c0 = [shape.x - 2, shape.y]; // ↗
            const c1 = [shape.x - 20, shape.y - 4]; // ↖
            const c2 = [xAxisPoint[0] - 20, xAxisPoint[1] - 4]; // ↙
            const c3 = [xAxisPoint[0] - 2, xAxisPoint[1]]; // ↘
            ctx
              .moveTo(c0[0], c0[1])
              .lineTo(c1[0], c1[1])
              .lineTo(c2[0], c2[1])
              .lineTo(c3[0], c3[1])
              .closePath();
          },
        });
        // 绘制右侧面
        const CubeRight = echarts.graphic.extendShape({
          shape: {
            x: 0,
            y: 0,
          },
          buildPath: function (ctx, shape) {
            const xAxisPoint = shape.xAxisPoint;
            const c1 = [shape.x - 3, shape.y]; // ↖
            const c2 = [xAxisPoint[0] - 3, xAxisPoint[1]]; // ↙
            const c3 = [xAxisPoint[0] + 15, xAxisPoint[1] - 4]; // ↘
            const c4 = [shape.x + 15, shape.y - 4]; // ↗
            ctx
              .moveTo(c1[0], c1[1])
              .lineTo(c2[0], c2[1])
              .lineTo(c3[0], c3[1])
              .lineTo(c4[0], c4[1])
              .closePath();
          },
        });
        // 绘制顶面
        const CubeTop = echarts.graphic.extendShape({
          shape: {
            x: 0,
            y: 0,
          },
          buildPath: function (ctx, shape) {
            const c1 = [shape.x - 5, shape.y]; // ↓
            const c2 = [shape.x + 15, shape.y - 4]; // →
            const c3 = [shape.x - 5, shape.y - 10]; // ↑
            const c4 = [shape.x - 20, shape.y - 4]; // ←
            ctx
              .moveTo(c1[0], c1[1])
              .lineTo(c2[0], c2[1])
              .lineTo(c3[0], c3[1])
              .lineTo(c4[0], c4[1])
              .closePath();
          },
        });
        // 注册三个面图形
        echarts.graphic.registerShape("CubeLeft", CubeLeft);
        echarts.graphic.registerShape("CubeRight", CubeRight);
        echarts.graphic.registerShape("CubeTop", CubeTop);
      }
      const COLOR = [
        "243, 11, 238",
        "0, 179, 247",
        "243, 11, 238",
        "0, 179, 247",
        "243, 11, 238",
        "0, 179, 247",
      ];
      const VALUE = this.item1Data.map((item) => Number(item.value));
      let maxNum = 0;
      VALUE.forEach((item) => {
        if (maxNum < item) {
          maxNum = item;
        }
      });
      // 计算最大值
      const MAX = VALUE.map(() => maxNum);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            const item = params[1];
            return item.name + " : " + item.value + "w";
          },
        },
        graphic: {
          elements: [
            {
              type: "image", // 图形元素类型
              top: "82%",
              style: {
                width: 500,
                image: item1Bg,
              },
            },
          ],
        },
        grid: {
          left: 10,
          right: 80,
          bottom: 0,
          top: 20,
          containLabel: true, // 保留label空间
        },
        xAxis: {
          type: "category",
          data: this.item1Data.map((item) => item.name),
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: "#ffffff",
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          min: 0,
          type: "value",
          position: "right",
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(250,172,255,0.5)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            inside: true,
            show: true,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            type: "custom",
            renderItem: function (params, api) {
              const location = api.coord([api.value(0), api.value(1)]);
              const color = `rgba(${COLOR[params.dataIndex]},0.3)`;
              return {
                type: "group",
                children: [
                  {
                    type: "CubeLeft",
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                  {
                    type: "CubeRight",
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                  {
                    type: "CubeTop",
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                ],
              };
            },
            data: MAX,
          },
          {
            type: "custom",
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)]);
              const color = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: `rgba(${COLOR[params.dataIndex]},1)`,
                },
                {
                  offset: 1,
                  color: `rgba(${COLOR[params.dataIndex]},0.4)`,
                },
              ]);
              return {
                type: "group",
                children: [
                  {
                    type: "CubeLeft",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                  {
                    type: "CubeRight",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                  {
                    type: "CubeTop",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                ],
              };
            },
            data: VALUE,
          },
          {
            type: "bar",
            label: {
              show: true,
              position: "top",
              fontSize: 14,
              fontWeight: 600,
              color: "#fff",
              offset: [-5, -10],
              formatter: (params) => {
                return VALUE[params.dataIndex] + "w";
              },
            },
            itemStyle: {
              color: "transparent",
            },
            data: MAX,
          },
        ],
      };
      if (
        (averageNum && averageNum !== "0") ||
        (expectNum && expectNum !== "0")
      ) {
        const markLine = {
          symbol: ["none", "circle"],
          lineStyle: {
            type: "dotted",
            width: 1,
            color: "rgba(250,172,255,0.5)",
          },
          data: [],
        };
        if (averageNum) {
          markLine.data.push({
            yAxis: averageNum,
            name: "平均发货量",
            label: {
              formatter: "{b}",
              color: "#DF278B",
            },
          });
        }

        if (expectNum) {
          markLine.data.push({
            yAxis: expectNum,
            name: "预估发货量",
            label: {
              formatter: "{b}",
              color: "#DF278B",
            },
          });
        }
        option.series[2].markLine = markLine;
      }
      this.chart1 = echarts.init(this.$refs.item1);
      this.chart1.setOption(option);
    },
    changeItem2Tab(code) {
      this.currentTab2 = code;
      this.renderItem2Top();
      this.renderItem2Btm();
    },
    async renderItem2Top() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/zyshdphb"
      );
      this.topChartData = data[this.currentTab2].top;
      this.topChart = echarts.init(this.$refs.topChart);
      let xLabel = this.topChartData.map((item) => item.name);
      const topChartOption = {
        tooltip: {},
        legend: {
          top: 0,
          right: 0,
          icon: "rect",
          itemHeight: 5,
          selectedMode: false,
          textStyle: {
            color: "#fff",
          },
        },
        animation: false,
        grid: {
          containLabel: true,
          top: "30",
          left: "3%",
          right: "3%",
          bottom: "3%", //也可设置left和right设置距离来控制图表的大小
        },
        xAxis: {
          data: xLabel,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#51367c",
            },
          },
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            margin: 10,
            fontSize: 12,
            textStyle: {
              color: "#fff", //X轴文字颜色
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "(万)",
            gridIndex: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#51367c",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: 10,
              fontSize: 12,
              textStyle: {
                color: "#fff", //X轴文字颜色
              },
            },
          },
        ],
        series: [
          // 累计出货量
          {
            type: "line",
            name: "累计出货量",
            smooth: true, //平滑曲线显示
            showAllSymbol: false, //显示所有图形。
            symbol: "none", //标记的图形为实心圆
            data: this.topChartData.map((item) => item.outValue),
            offset: ["-100%", 1],
            zlevel: 3,
          },
          {
            type: "bar",
            name: "累计出货量",
            barWidth: 8,
            barGap: "100%",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#287CE8",
                },
                {
                  offset: 1,
                  color: "#4f1670",
                },
              ]),
            },
            data: this.topChartData.map((item) => item.outValue),
            z: 10,
            zlevel: 0,
            label: {
              show: false,
            },
          },
          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#4f1670",
              },
            },
            symbolRepeat: "fixed",
            symbolMargin: 1,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [8, 2],
            symbolPosition: "start",
            symbolOffset: ["-100%", -1],
            data: this.topChartData.map((item) => item.outValue),
            width: 25,
            z: 0,
            zlevel: 1,
          },
          // 累计收货量
          {
            zlevel: 3,
            type: "line",
            name: "累计收货量",
            smooth: true,
            color: "#F2C347",
            symbol: "none",
            data: this.topChartData.map((item) => item.inValue),
            offset: ["-100%", 1],
          },
          {
            name: "累计收货量",
            type: "bar",
            barWidth: 8,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#F2C347",
                  },
                  {
                    offset: 1,
                    color: "#51196e",
                  },
                ]),
              },
            },
            data: this.topChartData.map((item) => item.inValue),
            z: 10,
            zlevel: 0,
            label: {
              show: false,
            },
          },
          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#4f1670",
              },
            },
            symbolRepeat: "fixed",
            symbolMargin: 1,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [8, 2],
            symbolPosition: "start",
            symbolOffset: ["100%", -1],
            data: this.topChartData.map((item) => item.inValue),
            width: 25,
            z: 0,
            zlevel: 1,
          },
        ],
      };
      this.topChart.setOption(topChartOption);
    },
    async renderItem2Btm() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/zyshdphb"
      );
      this.btmChartData = data[this.currentTab2].bottom;
      this.btmChart = echarts.init(this.$refs.btmChart);
      let xLabel = this.btmChartData.data.map((item) => item.name);
      let yLabel = this.btmChartData.data.map((item) => item.value);
      const btmChartOption = {
        legend: {
          show: false,
        },
        tooltip: {
          show: true,
          type: "line",
        },
        grid: {
          containLabel: true,
          top: "30",
          left: "3%",
          right: "3%",
          bottom: "3%", //也可设置left和right设置距离来控制图表的大小
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: xLabel,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "(万)",
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: this.btmChartData.name,
            type: "line",
            smooth: true,
            // showSymbol: false,/
            symbolSize: 4,
            zlevel: 3,
            lineStyle: {
              normal: {
                color: {
                  type: "linear",

                  colorStops: [
                    {
                      offset: 0,
                      color: "#00FE9D",
                    },
                    {
                      offset: 0.4,
                      color: "#02BFF1",
                    },
                    {
                      offset: 0.7,
                      color: "#339CFF",
                    },
                    {
                      offset: 1,
                      color: "#917FFF",
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0, // 左侧起点
                y: 0, // 左侧起点
                x2: 1, // 右侧终点
                y2: 0, // 右侧终点
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 254, 157, 0.5)",
                  },
                  {
                    offset: 0.4,
                    color: "rgba(2, 191, 241, 0.5)",
                  },
                  {
                    offset: 0.7,
                    color: "rgba(51, 156, 255, 0.5)",
                  },
                  {
                    offset: 1,
                    color: "rgba(145, 127, 255, 0.5)",
                  },
                ],
              },
            },
            data: yLabel,
          },
        ],
      };
      this.btmChart.setOption(btmChartOption);
    },
    initGeoMap() {
      china.features.forEach((v) => {
        // 地区名称
        var name = v.properties.name;
        // 地区经纬度
        this.geoCoordMap[name] = v.properties.cp;
      });
    },
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
    async renderItem3() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/yqwlfhdxx"
      );
      const form = data.from;
      this.item3Data = data.to;
      echarts.registerMap("china", china);
      this.item3 = echarts.init(this.$refs.item3);
      this.item3.setOption({
        legend: {
          show: false,
        },
        tooltip: {
          padding: 0,
          show: true,
          backgroundColor: "none",
          borderWidth: 0,
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          map: "china",
          top: "8%",
          left: "0",
          right: "0",

          label: {
            show: false,
          },
          tooltip: {
            show: false,
          },
          regions: [
            {
              name: "南海诸岛",
              value: 0,
              itemStyle: {
                opacity: 0,
                label: {
                  show: false,
                },
              },
            },
          ],
          itemStyle: {
            borderColor: "rgba(147, 235, 248, 0)",
            borderWidth: 0.5,
            areaColor: {
              x: 1000,
              y: 1000,
              x2: 1000,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#69c5d8", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#126caf", // 50% 处的颜色
                },
              ],
              global: true, // 缺省为 false
            },
            opacity: 1,
          },
          emphasis: {
            show: false,
            itemStyle: {
              areaColor: "#69c5d8",
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            top: "8.3%",
            left: "0",
            right: "0",
            roam: false,
            itemStyle: {
              areaColor: "#3f2d71",
              borderColor: "#1c1931",
            },
            tooltip: {
              show: true,
              formatter: (param) => {
                if (!param.value) {
                  return "";
                }
                let tem = `<div class="wuLiu-toolTip-wrapper">
                  `;
                param.data.cityList.forEach((item) => {
                  tem += `<div class="value">
                    <div>${item.name}:${item.value}</div>
                  </div>`;
                });
                return tem + '<div class="label">活跃用户数</div></div>';
              },
            },
            emphasis: {
              label: {
                show: true,
                color: "#fff",
              },
              itemStyle: {
                areaColor: "#005ec5",
              },
            },
            showLegendSymbol: false,
            data: this.item3Data,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            data: this.convertData(
              this.item3Data
                .sort(function (a, b) {
                  return b.value - a.value;
                })
                .slice(0, 10)
            ),
            symbolSize: 16,
            rippleEffect: {
              brushType: "stroke",
            },
            itemStyle: {
              color: "#df0074",
              shadowBlur: 10,
            },
            zlevel: 1,
          },
          {
            name: "飞线",
            type: "lines",
            zlevel: 2,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: "arrow",
              symbolSize: 8,
            },
            lineStyle: {
              color: "#df0074",
              width: 3,
              curveness: 0.2,
            },
            data: this.item3Data
              .map((item) => item.name)
              .map((name) => ({
                fromName: form,
                toName: name,
                coords: [this.geoCoordMap[form], this.geoCoordMap[name]],
              })),
          },
        ],
      });
      // 鼠标划入
      this.item3.on("mouseover", (e) => {
        // 停止定时器，清除之前的高亮
        clearInterval(this.mTime);
        this.mTime = "";
        if (e.dataIndex !== this.index) {
          this.item3.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: this.index,
          });
        }
      });
      // 鼠标划出重新定时器开始
      this.item3.on("mouseout", () => {
        this.lunbo();
      });
      this.lunbo();
    },
    lunbo() {
      this.mTime = setInterval(() => {
        if (!this.item3) {
          return false;
        }
        // 清除之前的高亮
        this.item3.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: this.index === -1 ? this.item3Data.length - 1 : this.index,
        });
        this.index = (this.index + 1) % this.dataLength;
        // 当前下标高亮
        this.item3.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: this.index,
        });
        // 显示tooltip
        this.item3.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: this.index,
        });
        if (this.index === this.item3Data.length - 1) {
          this.index = -1;
        }
      }, 2000);
    },
    async renderItem32() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/sfhsjxq"
      );
      this.item32Data = data;
    },
    changeItem4Tab(code) {
      this.currentTab4 = code;
      this.renderItem4();
    },
    async renderItem4() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/zyfhdsj2"
      );
      this.item4Data = data[this.currentTab4];
      this.item4Data.yData.bottom.data = this.item4Data.yData.bottom.data.map(
        (item) => item * -1
      );
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (p) => {
            return `收货：${p[0].value} | 发货：${p[1].value * -1}`;
          },
        },
        grid: {
          left: "2%",
          right: "4%",
          bottom: "4%",
          top: "10%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: 0,
          selectedMode: false,
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          data: this.item4Data.xData,
          axisLine: {
            lineStyle: {
              color: "#999",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            formatter: (p) => {
              const reg1 = new RegExp("-", "g"); // 加'g'，删除字符串里所有的"a"
              return `${p}`.replace(reg1, "");
            },
          },
        },
        series: [
          {
            name: this.item4Data.yData.top.name,
            data: this.item4Data.yData.top.data,
            type: "bar",
            barMaxWidth: "auto",
            barWidth: 30,
            barGap: "-100%",
            label: {
              show: false,
              position: "top",
              distance: 10,
              color: "#fff",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#116CFD",
                },
                {
                  offset: 0,
                  color: "#0BAEFD",
                },
              ]),
            },
          },
          {
            name: this.item4Data.yData.bottom.name,
            data: this.item4Data.yData.bottom.data,
            type: "bar",
            barMaxWidth: "auto",
            barWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#E92585",
                },
                {
                  offset: 0,
                  color: "#7C3ABF",
                },
              ]),
            },
            label: {
              show: false,
              position: "top",
              distance: 10,
              color: "#fff",
            },
          },
        ],
      };
      this.item4 = echarts.init(this.$refs.item4);
      this.item4.setOption(option);
    },
    changeItem5Tab(code) {
      this.currentTab5 = code;
      this.renderItem5();
    },
    async renderItem5() {
      const { data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/zyshdphb2"
      );
      this.item5Data = data[this.currentTab5];
      let maxData = null;
      const getMaxData = () => {
        //合并
        let tempList = [
          ...this.item5Data.yData.left.data.value,
          ...this.item5Data.yData.left.userData.value,
          ...this.item5Data.yData.right.data.value,
          ...this.item5Data.yData.right.userData.value,
        ];
        //取绝对值
        tempList.forEach((elem, index) => {
          if (elem < 0) {
            tempList[index] = -elem;
          }
        });
        //排序找出最大
        tempList.sort(function (a, b) {
          return b - a;
        });
        return tempList[0];
      };
      maxData = getMaxData();
      this.item5 = echarts.init(this.$refs.item5);
      this.item5.setOption({
        grid: [
          {
            show: false,
            left: "8%",
            top: "3%",
            bottom: "8%",
            containLabel: true,
            width: "35%",
          },
          {
            show: false,
            left: "50%",
            top: "3%",
            bottom: "8%",
            width: "20%",
          },
          {
            show: false,
            right: "8%",
            top: "3%",
            bottom: "8%",
            containLabel: true,
            width: "35%",
          },
        ],
        xAxis: [
          {
            gridIndex: 0,
            type: "value",
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            max: maxData,
          },
          {
            gridIndex: 1,
            show: true,
            axisLabel: {
              color: "#fff",
            },
          },
          {
            gridIndex: 2,
            type: "value",
            inverse: false,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            max: maxData,
          },
        ],
        yAxis: [
          {
            type: "category",
            gridIndex: 0,
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
          {
            gridIndex: 1,
            type: "category",
            show: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: -1, //margin 配合 align 调整位置
              align: "center",

              textStyle: {
                color: "#fff",
                align: "center",
              },
            },
            data: this.item5Data.xData,
          },
          {
            type: "category",
            gridIndex: 2,
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: this.item5Data.yData.left.data.value,
            type: "bar",
            barWidth: "20",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#47E5FF",
                  },
                  {
                    offset: 1,
                    color: "#3178F8",
                  },
                ]),
              },
            },
            label: {
              show: true,
              color: "#fff",
              position: "left",
            },
          },
          {
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: this.item5Data.yData.left.userData.value,
            type: "bar",
            barWidth: "20",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#00799e",
                  },
                  {
                    offset: 1,
                    color: "#08459f",
                  },
                ]),
              },
            },
            label: {
              show: true,
              color: "#fff",
              position: "left",
            },
          },
          {
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            barWidth: "20",
          },
          {
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: this.item5Data.yData.right.data.value,
            type: "bar",
            barWidth: "20",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#7927ad",
                  },
                  {
                    offset: 1,
                    color: "#ff0095",
                  },
                ]),
              },
            },
            label: {
              show: true,
              color: "#fff",
              position: "right",
            },
          },
          {
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: this.item5Data.yData.right.userData.value,
            type: "bar",
            barWidth: "20",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#421c69",
                  },
                  {
                    offset: 1,
                    color: "#89055c",
                  },
                ]),
              },
            },
            label: {
              show: true,
              color: "#fff",
              position: "right",
            },
          },
        ],
      });
    },
    resizeChart() {
      this.item1 && this.item1.resize();
      this.topChart && this.topChart.resize();
      this.btmChart && this.btmChart.resize();
      this.item3 && this.item3.resize();
      this.item4 && this.item4.resize();
      this.item5 && this.item5.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
.wuLiu-container {
  display: grid;
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/wuliu/wuliuBg.png");
  background-size: 100% 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr 1.8fr 1fr 0.7fr 1.3fr 1fr 1fr 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "title title title title title title title title title title title title title"
    "leftTitle leftTitle leftTitle leftTitle centerTitle centerTitle centerTitle centerTitle centerTitle rightTitle rightTitle rightTitle rightTitle"
    "item1 item1 item1 item1 item3 item3 item3 item3 item3 item4 item4 item4 item4"
    "item1 item1 item1 item1 item3 item3 item3 item3 item3 item4 item4 item4 item4"
    "item1 item1 item1 item1 item3 item3 item3 item3 item3 item4 item4 item4 item4"
    "item2 item2 item2 item2 item3 item3 item3 item3 item3 item5 item5 item5 item5"
    "item2 item2 item2 item2 item3 item3 item3 item3 item3 item5 item5 item5 item5"
    "item2 item2 item2 item2 item3 item3 item3 item3 item3 item5 item5 item5 item5"
    "item2 item2 item2 item2 item3 item3 item3 item3 item3 item5 item5 item5 item5";
}

.item1 {
  grid-area: item1;
}

.item3 {
  grid-area: item3;
  background: url("~@/assets/wuliu/center-bg.png");
  background-size: 100% 100%;
  position: relative;
  .item3-title {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .title {
      margin: 0 auto;
      width: 70%;
      height: 40px;
      background: url("~@/assets/wuliu/title.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
  .map {
    width: 100%;
    height: 80%;
  }
  .dataInfo {
    width: 100%;
    height: 20%;
    background: url("~@/assets/wuliu/data-box.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: bold;
      width: 70%;
      height: 34px;
      margin: 0 auto;
      background: url("~@/assets/wuliu/sub-title.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .info {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .info-name {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-bottom: 10px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    .info-value {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00f6ff;
      border-top: 2px solid;
      border-bottom: 2px solid;
      border-image: linear-gradient(
          to right,
          rgba(173, 18, 160, 0.1),
          #ad12a0,
          rgba(173, 18, 160, 0.1)
        )
        1;
    }
  }
}

.item2 {
  grid-area: item2;
  .chartContent {
    display: flex;
    flex-direction: column;
  }
  .topChart,
  .btmChart {
    flex: 1;
  }
}

.item4 {
  grid-area: item4;
}

.item5 {
  grid-area: item5;
  .item5-top {
    height: 30px;
    display: flex;
    .left,
    .right {
      width: 43%;
      height: 100%;
      .text {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px;
        border-top: 1px solid #2b6790;
        border-bottom: 1px solid #2b6790;
      }
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .text {
        background-image: linear-gradient(to right, #210c4d, #5a1373);
      }
    }
    .right {
      display: flex;
      .text {
        background-image: linear-gradient(to left, #210c4d, #5a1373);
      }
    }
  }
  .item5-bottom {
    height: 30px;
    display: flex;
    justify-content: space-around;

    .item5-bottom-item {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .icon {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
    .icon1 {
      background-image: linear-gradient(to left, #3178f8, #47e5ff);
    }
    .icon2 {
      background-image: linear-gradient(to left, #08459f, #00799e);
    }
    .icon3 {
      background-image: linear-gradient(to left, #ff0095, #7927ad);
    }
    .icon4 {
      background-image: linear-gradient(to left, #89055c, #421c69);
    }
  }
}

.leftTitle {
  grid-area: leftTitle;
}

.rightTitle {
  grid-area: rightTitle;
}

.centerTitle {
  grid-area: centerTitle;
}

.title {
  grid-area: title;
}
.topTitle {
  display: flex;
  align-items: center;
  .text {
    padding-left: 10px;
    border-left: 4px solid #680074;
    font-size: 20px;
    font-weight: bold;
    background-image: -webkit-linear-gradient(bottom, #f6008a, #7f27ab);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.chartContent {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss">
.wuLiu-toolTip-wrapper {
  color: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #e82486;
  .value {
    min-width: 70px;
    padding: 10px 14px 10px 0;
    background: #010e26;
    div {
      border-left: 3px solid #fe1876;
      padding-left: 10px;
      text-align: center;
    }
  }
  .label {
    padding: 10px 14px;
    background: linear-gradient(to right, #ff31b5, #6730a2);
  }
}
</style>
