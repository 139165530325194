<template>
  <div class="item4-2-page">
    <div style="width: 100%; height: 100%" ref="item42"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import china from "./china.json";
export default {
  name: "item4-2",
  data() {
    return {
      mTime: "", // 用定时器控制高亮
      dataLength: china.features.length,
      index: -1,
      geoCoordMap: {},
      item42: null,
      item42Data: { cityData: [] },
    };
  },
  mounted() {
    this.initGeoMap();
    this.renderItem42();
    window.addEventListener("resize", this.resizeChart);
  },
  beforeDestroy() {
    if (this.mTime) {
      clearInterval(this.mTime);
      this.mTime = "";
    }
    this.item42 && this.item42.dispose();
    window.removeEventListener("resize", this.resizeChart);
  },
  methods: {
    initGeoMap() {
      china.features.forEach((v) => {
        // 地区名称
        var name = v.properties.name;
        // 地区经纬度
        this.geoCoordMap[name] = v.properties.cp;
      });
    },
    async renderItem42() {
      const res = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/mapData"
      );
      this.item42Data.cityData = res.data;
      echarts.registerMap("china", china);
      this.item42 = echarts.init(this.$refs.item42);
      this.item42.setOption({
        color: ["#f7b340", "#4FCB74"],
        legend: {
          show: false,
        },
        tooltip: {
          padding: 0,
          show: true,
          backgroundColor: "none",
          borderWidth: 0,
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          map: "china",
          top: "4.5%",
          left: "11%",
          aspectScale: 0.75,
          label: {
            show: false,
          },
          tooltip: {
            show: false,
          },
          regions: [
            {
              name: "南海诸岛",
              value: 0,
              itemStyle: {
                opacity: 0,
                label: {
                  show: false,
                },
              },
            },
          ],
          itemStyle: {
            borderColor: "rgba(147, 235, 248, 0)",
            borderWidth: 0.5,
            areaColor: {
              x: 1000,
              y: 1000,
              x2: 1000,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#69c5d8", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#126caf", // 50% 处的颜色
                },
              ],
              global: true, // 缺省为 false
            },
            opacity: 1,
          },
          emphasis: {
            show: false,
            itemStyle: {
              areaColor: "#69c5d8",
            },
          },
          z: 2,
        },
        series: [
          {
            type: "map",
            map: "china",
            tooltip: {
              show: true,
              formatter: (param) => {
                if (!param.value) {
                  return "";
                }
                let tem = `<div class="tooltip-wrapper">
                  <div style="color:#f00066;font-weight: bold;">· 活跃城市</div>`;
                this.item42Data.cityData[param.dataIndex].cityList.forEach(
                  (item) => {
                    let tem1 = `
                    <div class="city-name">${param.name} ${item.name}</div>
                    <div class="city-value">活跃用户：${item.activeUser}  |  潜力用户：${item.potentialUser}</div>
                    `;
                    tem += tem1;
                  }
                );
                return tem + "</div>";
              },
            },
            top: "5%",
            left: "11%",
            aspectScale: 0.75,
            roam: false,
            itemStyle: {
              areaColor: "#3f2d71",
              borderColor: "#1c1931",
            },
            emphasis: {
              label: {
                show: true,
                color: "#fff",
              },
              itemStyle: {
                areaColor: "#005ec5",
              },
            },
            markPoint: {
              symbol: "circle",
              symbolSize: 5,
              animation: false,
            },
            zlevel: 1,
            data: this.item42Data.cityData,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            data: this.convertData(
              this.item42Data.cityData
                .sort(function (a, b) {
                  return b.value - a.value;
                })
                .slice(0, 10)
            ),
            symbolSize: 12,
            rippleEffect: {
              brushType: "stroke",
            },
            itemStyle: {
              color: "#ff0075",
              shadowBlur: 10,
            },
            zlevel: 1,
          },
        ],
      });
      // 鼠标划入
      this.item42.on("mouseover", (e) => {
        // 停止定时器，清除之前的高亮
        clearInterval(this.mTime);
        this.mTime = "";
        //e.dataIndex!=index--请看下面说明
        if (e.dataIndex != this.index) {
          this.item42.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: this.index,
          });
        }
      });
      // 鼠标划出重新定时器开始
      this.item42.on("mouseout", () => {
        this.lunbo();
      });
      this.lunbo();
    },
    lunbo() {
      this.mTime = setInterval(() => {
        // 清除之前的高亮
        this.item42.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex:
            this.index === -1
              ? this.item42Data.cityData.length - 1
              : this.index,
        });
        this.index = (this.index + 1) % this.dataLength;
        // 当前下标高亮
        this.item42.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: this.index,
        });
        // 显示tooltip
        this.item42.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: this.index,
        });
        if (this.index === this.item42Data.cityData.length - 1) {
          this.index = -1;
        }
      }, 2000);
    },
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
    resizeChart() {
      this.item42 && this.item42.resize();
    },
  },
};
</script>

<style lang="scss" scoped>
.item4-2-page {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/light.png");
  background-size: 100% 100%;
}
</style>
<style>
.tooltip-wrapper {
  min-width: 200px;
  min-height: 100px;
  background: #040308;
  color: "#fff";
  padding: 10px;
}
.echarts-tooltip {
  padding: 0;
}
.city-name {
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
}
.city-value {
  font-size: 12px;
  margin-left: 10px;
  color: #c8c8c8;
}
</style>
