<template>
  <div class="v-card-wrapper">
    <div class="card-title flex y-center">
      <span>{{ title }}</span>
      <div class="tabs flex">
        <div
          v-for="item in tabs"
          :class="{ 'tab-item': true, currentTab: item.code === currentTab }"
          :key="item.code"
          @click="changeTab(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "vCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    showTab: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    currentTab: {
      type: String,
      default: "",
    },
  },
  methods: {
    changeTab(tab) {
      if (this.currentTab === tab.code) {
        return false;
      }
      this.$emit("changeTab", tab.code);
    },
  },
};
</script>

<style scoped lang="scss">
.v-card-wrapper {
  padding: 15px;
  width: 100%;
  height: 100%;
  background: rgba(40, 33, 80, 0.45);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.card-title {
  justify-content: space-between;
  height: 30px;
  background: url("~@/assets/title box.png") no-repeat;
  background-size: 50% 100%;
  padding: 0 10px;
  font-weight: bold;
  margin-bottom: 10px;
}
.card-content {
  flex: 1;
}
.tabs {
  font-size: 12px;
  font-weight: normal;
  border-radius: 2px;
  overflow: hidden;
  .tab-item {
    padding: 5px;
    background: #2f2d5b;
    cursor: pointer;
  }
  .currentTab {
    background: #545189;
  }
}
</style>
