<template>
  <div class="supplier-page">
    <div class="key-supplier">
      <div class="key-supplier-title">重点供应商</div>
      <div class="key-supplier-content">
        <div
          class="key-supplier-img"
          v-for="(item, index) in keySupplierImg"
          :key="index"
        >
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
    <div class="cooperative-supplier">
      <div class="cooperative-supplier-title">
        <span></span>
        合作供应商
        <span></span>
      </div>
      <div class="cooperative-supplier-content">
        <div
          class="cooperative-supplier-img"
          v-for="(item, index) in cooperativeSupplierImg"
          :key="index"
        >
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuppLier",
  data() {
    return {
      keySupplierImg: [],
      cooperativeSupplierImg: [],
    };
  },
  mounted() {
    this.getGysData();
  },
  methods: {
    // 获取供应商数据
    async getGysData() {
      const { data: zdGysData } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/zdgys"
      );
      const { data: hzGysData } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/hzgys"
      );
      this.keySupplierImg = zdGysData;
      this.cooperativeSupplierImg = hzGysData;
      // console.log(zdGysData);
      // console.log(hzGysData);
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier-page {
  width: 90%;
  margin: 0 auto;
  height: 100%;
}
.key-supplier {
  width: 100%;
  height: 32%;
  background: url("~@/assets/pinpai/bg-key-point.png") no-repeat;
  background-size: 100% 100%;
  &-title {
    background: url("~@/assets/pinpai/key-point.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-size: 17px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  &-img {
    width: 19%;
    height: 20%;
    margin: 5px 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.cooperative-supplier {
  width: 100%;
  height: 38%;
  margin-top: 12px;
  // padding-top: 15px;
  background: url("~@/assets/pinpai/bg-cooperation.png") no-repeat;
  background-size: 100% 100%;
  &-title {
    background: url("~@/assets/pinpai/cooperation.png") no-repeat;
    background-size: 33% 100%;
    background-position: center center;
    text-align: center;
    font-size: 17px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: block;
      width: 4px;
      height: 4px;
      margin: 4px;
      background: #ffffff;
      border-radius: 50%;
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  &-img {
    width: 13%;
    height: 20%;
    margin: 5px 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
