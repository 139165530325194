<template>
  <div id="app">
    <IndexPage></IndexPage>
  </div>
</template>

<script>
import IndexPage from "@/views/index.vue";
export default {
  components: { IndexPage },
};
</script>
<style>
#app {
  width: 100vw;
  height: 100vh;
}
</style>
