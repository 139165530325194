<template>
  <div class="item4-1-page">
    <div class="item4-title">
      <span class="label">开播端：</span> <span>{{ item41Data.app }}</span>
      <span class="label">开播时间：</span><span>{{ item41Data.date }}</span>
      <span class="label">直播时长：</span><span>{{ item41Data.timer }}</span>
    </div>
    <div class="big-title flex-center">
      <div style="position: relative">
        易心电商直播间成交金额
        <span style="position: absolute; right: -100px; bottom: 0">
          {{ item41Data.dataUpdateTitle }}
        </span>
      </div>
    </div>
    <div class="money flex-center">
      <div class="money-item">￥</div>
      <div
        class="money-item"
        v-for="(item, index) in item41Data.money"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="flex infoList">
      <div
        class="infoItem"
        v-for="(item, index) in item41Data.infoList"
        :key="index"
      >
        <div class="label">{{ item.label }}</div>
        <div class="value">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "item4-1",
  data() {
    return {
      item41Data: {},
    };
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.$axios("https://mock.apifox.cn/m1/2727236-0-default/zbjcjje").then(
        (res) => {
          this.item41Data = res.data;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.item4-1-page {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  background: url("~@/assets/itemBg.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  padding: 10px;
}
.item4-title {
  font-size: 12px;
  span {
    display: inline-block;
    transform: scale(0.9);
  }
  .label {
    color: #dcb6f3;
    margin-left: 10px;
  }
}
.big-title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  span {
    letter-spacing: normal;
    font-size: 12px;
    font-weight: 400;
    color: #be93c1;
  }
}
.money {
  margin: 10px 0;
}
.money-item {
  background: linear-gradient(#6b2b83, #571874);
  margin-right: 5px;
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 3px 0 0;
  font-weight: bold;
  font-size: 18px;
}
.infoList {
  flex-wrap: wrap;
}
.infoItem {
  width: 20%;
  margin-bottom: 10px;
  padding-left: 20px;
  .label {
    font-size: 12px;
    color: #be93c1;
  }
  .value {
    font-size: 14px;
  }
}
</style>
