<template>
  <div class="page-title">
    <div class="flex">
      <div class="title-date">
        <span class="title-label">DATE：</span>{{ date }}
      </div>
      <div class="title-time">
        <span class="title-label">TIME：</span>{{ time }}
      </div>
    </div>
    <div class="flex">
      <div class="title-date title-date-right flex-right">
        <span class="title-label">WEATHER：</span>
        <span style="margin-right: 10px">{{ titleData.tianqi }}</span>
        <img
          style="width: 20px; height: 20px; margin-right: 30px"
          src="@/assets/icon-cloudy.png"
          alt=""
        />
      </div>
      <div class="title-time title-date-right flex-right">
        <span class="title-label">TIMER：</span
        ><span class="title-value"> {{ titleData.timer }} </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pageTitle",
  data() {
    return {
      date: "",
      time: "",
      titleData: {},
    };
  },
  created() {
    this.$axios("https://mock.apifox.cn/m1/2727236-0-default/title").then(
      (res) => {
        this.titleData = res.data;
      }
    );
    setInterval(() => {
      this.getCurrentDate(new Date(), true);
    }, 1000);
  },
  methods: {
    getCurrentDate(timestamp, type) {
      var date = new Date(type ? timestamp : timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      this.date = Y + M + D;
      this.time = h + m + s;
    },
  },
};
</script>

<style scoped lang="scss">
.page-title {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/top.png");
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
.title-date {
  width: 200px;
  font-size: 14px;
  padding-top: 5px;
  background-image: url("~@/assets/datebox.png");
  background-repeat: no-repeat;
}
.title-date-right {
  background-image: url("~@/assets/timer box.png") !important;
  background-position: right top;
  .title-label {
    margin-left: 0;
  }
  .title-value {
    margin-right: 35px;
  }
}
.title-time {
  width: 200px;
  font-size: 14px;
  margin-left: 20px;
  padding-top: 5px;
  background-image: url("~@/assets/time box.png");
  background-repeat: no-repeat;
}
.title-label {
  margin-left: 35px;
  color: #bd4de5;
  font-weight: bold;
}
</style>
