<template>
  <div class="home-container">
    <div class="title"><vPageTitle></vPageTitle></div>
    <div class="item1">
      <vCard title="观众来源分布">
        <div class="chartContent" ref="item1"></div>
      </vCard>
    </div>
    <div class="item2">
      <vCard title="平台交易额占比">
        <div class="chartContent" ref="item2"></div>
      </vCard>
    </div>
    <div class="item3">
      <vCard title="流量转化数据">
        <!-- <div class="chartContent" ref="item3"></div> -->
        <div class="chartContent" id="item3">
          <div
            :class="['row_item', item.flag]"
            v-for="(item, index) in item3Lines"
            :key="index"
          >
            <div class="r_left">
              <div class="l_txt">{{ item.lTitle }}</div>
              <div class="b_line"></div>
            </div>
            <div class="r_right">
              <div class="r_t_left">{{ item.rTitleL }}</div>
              <div class="r_t_right">{{ item.rTitleR }}</div>
            </div>
            <div class="b_arrow"></div>
          </div>
        </div>
      </vCard>
    </div>
    <div class="item4 flex">
      <div class="item4-1">
        <vItem41></vItem41>
      </div>
      <div class="item4-2">
        <vItem42></vItem42>
      </div>
    </div>
    <div class="item5">
      <vCard title="各类产品交易额">
        <div class="chartContent" ref="item5"></div>
      </vCard>
    </div>
    <div class="item6">
      <vCard title="用户画像分析">
        <div class="chartContent flex">
          <div class="flex1" ref="item61"></div>
          <div class="flex1" ref="item62"></div>
          <div class="flex1" ref="item63"></div>
        </div>
      </vCard>
    </div>
    <div class="item7">
      <vCard title="用户性别画像">
        <div class="item7-wrapper">
          <div class="flex item7-header">
            <div class="flex-center" style="color: #ff29d8">
              <img src="@/assets/icon-female.png" alt="" />
              <span class="label">女性</span>
              <span class="value">{{ item7Data.woman }}</span>
            </div>
            <div class="flex-center" style="color: #598aee">
              <span class="value">{{ item7Data.man }}</span>
              <span class="label">男性</span>
              <img src="@/assets/icon-male.png" alt="" />
            </div>
          </div>
          <div class="item7-content flex">
            <div
              :style="{
                height: '100%',
                background: '#ff29d8',
                width: item7Data.woman,
              }"
            ></div>
            <div
              :style="{
                height: '100%',
                background: '#598aee',
                width: item7Data.man,
              }"
            ></div>
          </div>
        </div>
      </vCard>
    </div>
    <div class="item8">
      <vCard title="热词搜索">
        <div class="chartContent" ref="item8"></div>
      </vCard>
    </div>
  </div>
</template>

<script>
import vPageTitle from "@/components/pageTitle.vue";
import vCard from "@/components/card.vue";
import * as echarts from "echarts";
import "echarts-liquidfill";
import "echarts-wordcloud";
import vItem41 from "./item4-1.vue";
import vItem42 from "./item4-2.vue";
import icon1 from "@/assets/icon-people.png";
import icon2 from "@/assets/icon-transformation.png";
export default {
  name: "HomeView",
  components: {
    vPageTitle,
    vCard,
    vItem41,
    vItem42,
  },
  data() {
    return {
      item1: null,
      item2: null,
      // item3: null,
      item3Lines: [],
      item5: null,
      item6Data: {},
      item61: null,
      item62: null,
      item63: null,
      item7: null,
      item7Data: {},
      item8: null,
    };
  },
  created() {
    this.$axios("https://mock.apifox.cn/m1/2727236-0-default/llzhsj2").then(
      ({ data }) => {
        this.item3Lines = data;
      }
    );
  },
  mounted() {
    this.renderItem1();
    this.renderItem2();
    // this.renderItem3();
    this.renderItem5();
    this.renderItem6();
    this.renderItem7();
    this.renderItem8();
    window.addEventListener("resize", this.resizeChart);
  },
  methods: {
    // 观众来源
    async renderItem1() {
      const { data: item1Data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/gzlyfb"
      );
      this.item1 = echarts.init(this.$refs.item1);
      this.item1.setOption({
        series: [
          {
            type: "pie",
            minAngle: 30,
            startAngle: 260,
            data: item1Data.leftData.map((item) => ({
              name: item.label,
              value: item.value,
              label: {
                show: true,
                align: "left",
                padding: [0, -88, 20, -80],
                formatter: (params) => {
                  return "{text|" + params.name + params.value + "%}";
                },
                rich: {
                  text: {
                    color: item.color,
                  },
                },
              },
              labelLine: {
                show: true,
                length: 15,
                length2: 80,
              },
              itemStyle: {
                color: {
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      offset: 0,
                      color: item.color,
                    },
                    {
                      offset: 1,
                      color: item.color,
                    },
                  ],
                },
              },
            })),
            animation: false,
            radius: ["50%", "75%"],
            center: ["25%", "50%"],
            hoverAnimation: false,
          },
          {
            type: "pie",
            minAngle: 30,
            startAngle: 270,
            data: item1Data.rightData.map((item) => ({
              name: item.label,
              value: item.value,
              label: {
                show: true,
                align: "left",
                padding: [0, -70, 20, -80],
                formatter: (params) => {
                  return "{text|" + params.name + params.value + "%}";
                },
                rich: {
                  text: {
                    color: item.toColor,
                  },
                },
              },
              labelLine: {
                show: true,
                length: 15,
                length2: 80,
              },
              itemStyle: {
                color: {
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      offset: 0,
                      color: item.color,
                    },
                    {
                      offset: 1,
                      color: item.toColor,
                    },
                  ],
                },
              },
            })),
            animation: false,
            radius: ["50%", "75%"],
            center: ["70%", "50%"],
            hoverAnimation: false,
          },
        ],
      });
    },
    // 平台交易额占比
    async renderItem2() {
      const { data: item2Data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/ptjyezb"
      );
      this.item2 = echarts.init(this.$refs.item2);
      const series = item2Data.map((item) => {
        return {
          type: "liquidFill",
          radius: "65%",
          center: [item.center, "50%"],
          data: [item.value, item.value - 0.1], // data个数代表波浪数
          color: [
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 1,
                  color: item.color,
                },
                {
                  offset: 0,
                  color: item.toColor,
                },
              ],
              globalCoord: false,
            },
          ],
          backgroundStyle: {
            color: "#020c3d",
          },
          label: {
            position: ["50%", "116%"],
            formatter: "累计占比" + "{value| " + item.value * 100 + "%}",
            rich: {
              value: {
                color: item.toColor,
              },
            },
            color: "#fff",
            fontSize: 14,
          },
          outline: {
            borderDistance: 0,
            itemStyle: {
              borderWidth: 2,
              borderColor: "#041c58",
            },
          },
        };
      });
      this.item2.setOption({
        title: item2Data.map((item) => ({
          text: "· " + item.label,
          top: 0,
          left: item.titleCenter,
          textStyle: {
            color: item.toColor,
            fontSize: 14,
          },
        })),
        series,
      });
    },
    // 流量转化数据
    async renderItem3() {
      const { data: item3Data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/llzhsj"
      );
      this.item3 = echarts.init(this.$refs.item3);
      this.item3.setOption({
        legend: {
          show: true,
          data: [
            { name: "总人数", icon: `image://${icon1}` },
            { name: "转化量", icon: `image://${icon2}` },
          ],
          itemWidth: 15,
          itemHeight: 15,
          left: 0,
          top: 0,
          selectedMode: false,
          textStyle: {
            color: "#FFF",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: item3Data.xData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            color: "#164383",
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
        },
        series: [
          {
            z: 3,
            name: "总人数",
            type: "pictorialBar",
            symbolPosition: "end",
            data: item3Data.yData.map((item) => item.total),
            symbol: "diamond",
            symbolOffset: ["-12", "-50%"],
            symbolSize: [19, 10],
            itemStyle: {
              borderColor: "#009bf7",
              color: "#009bf7",
            },
          },

          {
            z: 2,
            type: "bar",
            name: "总人数",
            barWidth: 20,
            data: item3Data.yData.map((item) => item.total),
            label: {
              show: true,
              position: "top",
              distance: 10,
              color: "#009bf7",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                x2: 1,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "#007ebf" },
                  { offset: 0.5, color: "#007ebf" },
                  { offset: 0.5, color: "#0b2d56" },
                  { offset: 1, color: "#0b2d56" },
                ],
              },
            },
          },

          {
            z: 3,
            name: "转化量",
            type: "pictorialBar",
            symbolPosition: "end",
            data: item3Data.yData.map((item) => item.num),
            symbol: "diamond",
            symbolOffset: ["12", "-50%"],
            symbolSize: [19, 10],
            itemStyle: {
              borderColor: "#fc0085",
              color: "#fc0085",
            },
          },

          {
            z: 2,
            type: "bar",
            name: "转化量",
            barWidth: 20,
            data: item3Data.yData.map((item) => item.num),
            label: {
              show: true,
              position: "top",
              distance: 10,
              color: "#fd008b",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                x2: 1,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "#e6007e" },
                  { offset: 0.5, color: "#e6007e" },
                  { offset: 0.5, color: "#790a58" },
                  { offset: 1, color: "#790a58" },
                ],
              },
            },
          },
        ],
      });
    },
    // 各类产品交易额
    async renderItem5() {
      const { data: item5Data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/glcpjye"
      );
      this.item5 = echarts.init(this.$refs.item5);
      this.item5.setOption({
        color: item5Data.map((item) => item.color),
        tooltip: {
          show: false,
        },
        grid: {
          containLabel: true,
          left: 0,
          right: 0,
          bottom: 0,
          top: 30,
        },
        xAxis: {
          axisLabel: {
            color: "#c0c3cd",
            interval: 0,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: item5Data.map((item) => item.label),
          type: "category",
        },
        yAxis: {
          type: "value",
          name: "(亿)", //单位
          nameLocation: "end",
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            data: item5Data.map((item) => item.value),
            type: "bar",
            barWidth: 25,
            zlevel: 2,
            itemStyle: {
              color: (p) => {
                return {
                  type: "linear",
                  x: 0,
                  x2: 1,
                  y: 0,
                  y2: 0,
                  colorStops: [
                    { offset: 0, color: item5Data[p.dataIndex].color },
                    { offset: 0.5, color: item5Data[p.dataIndex].color },
                    { offset: 0.5, color: item5Data[p.dataIndex].toColor },
                    { offset: 1, color: item5Data[p.dataIndex].toColor },
                  ],
                };
              },
            },
            label: {
              show: true,
              position: "top",
              distance: 10,
              color: "#fff",
            },
          },
          {
            zlevel: 3,
            data: item5Data.map(() => 1),
            type: "pictorialBar",
            barMaxWidth: "20",
            symbol: "diamond",
            symbolOffset: [0, "50%"],
            symbolSize: [25, 10],
            itemStyle: {
              color: (p) => {
                return item5Data[p.dataIndex].toColor;
              },
            },
          },
          {
            zlevel: 3,
            data: item5Data.map((item) => item.value),
            type: "pictorialBar",
            barMaxWidth: "20",
            symbolPosition: "end",
            symbol: "diamond",
            symbolOffset: [0, "-50%"],
            symbolSize: [25, 10],
            itemStyle: {
              color: (p) => {
                return item5Data[p.dataIndex].toColor;
              },
            },
          },
        ],
      });
    },
    // 用户画像分析
    async renderItem6() {
      this.item61 = echarts.init(this.$refs.item61);
      this.item62 = echarts.init(this.$refs.item62);
      this.item63 = echarts.init(this.$refs.item63);
      const res = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/yhhxfx"
      );
      this.item6Data = res.data;
      this.item61.setOption(this.getItem6Options("activeUser"));
      this.item62.setOption(this.getItem6Options("buyer"));
      this.item63.setOption(this.getItem6Options("yearUser"));
    },
    getItem6Options(code) {
      const data = this.item6Data[code];
      let total = 0;
      data.forEach((item) => (total += item.value));
      return {
        title: {
          text: this.item6Data[`${code}Total`],
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          subtext: this.item6Data[`${code}Title`],
          subtextStyle: {
            color: "#b3b4de",
          },
          top: "30%",
          left: "center",
        },
        legend: {
          show: true,
          orient: "vertical",
          top: "bottom",
          left: "center",
          textStyle: {
            color: "#f2f2f2",
            fontSize: 12,
          },
          icon: "rect",
          itemWidth: 10,
          itemHeight: 10,
          formatter: (p) => {
            const info = data.find((item) => item.name === p);
            const baifenbi = ((info.value / total) * 100).toFixed(1);
            return `${p} ${baifenbi}%`;
          },
        },
        series: [
          // 主要展示层的
          {
            radius: ["50%", "60%"],
            center: ["50%", "40%"],
            type: "pie",
            itemStyle: {
              color: (params) => {
                return this.item6Data[code][params.dataIndex].color;
              },
            },
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
            data: this.item6Data[code],
          },
          // 边框的设置
          {
            radius: ["60%", "70%"],
            center: ["50%", "40%"],
            type: "pie",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            animation: false,
            tooltip: {
              show: false,
            },
            itemStyle: {
              color: "#14356b",
            },
            data: [
              {
                value: 1,
              },
            ],
          },
        ],
      };
    },
    async renderItem7() {
      const res = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/yhxbhx"
      );
      this.item7Data = res.data;
    },
    // 热词搜索
    async renderItem8() {
      const { data: item8Data } = await this.$axios(
        "https://mock.apifox.cn/m1/2727236-0-default/rcss"
      );
      this.item8 = echarts.init(this.$refs.item8);
      const color = ["#ff00cc", "#0051ea", "#980054"];
      this.item8.setOption({
        series: [
          {
            type: "wordCloud",
            gridSize: 20,
            sizeRange: [16, 50],
            rotationRange: [0, 90],
            rotationStep: 90,
            textStyle: {
              color: () => {
                const r = Math.floor(Math.random() * color.length);
                return color[r];
              },
            },
            width: "100%",
            height: "100%",
            data: item8Data,
          },
        ],
      });
    },
    resizeChart() {
      this.item1 && this.item1.resize();
      this.item2 && this.item2.resize();
      this.item3 && this.item3.resize();
      this.item5 && this.item5.resize();
      this.item61 && this.item61.resize();
      this.item62 && this.item62.resize();
      this.item63 && this.item63.resize();
      this.item8 && this.item8.resize();
    },
  },
  beforeDestroy() {
    this.item1 && this.item1.dispose();
    this.item2 && this.item2.dispose();
    this.item3 && this.item3.dispose();
    this.item5 && this.item5.dispose();
    this.item61 && this.item61.dispose();
    this.item62 && this.item62.dispose();
    this.item63 && this.item63.dispose();
    this.item8 && this.item8.dispose();
    window.removeEventListener("resize", this.resizeChart);
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  width: 100vw;
  height: 100vh;
  background: #1e0732 url("~@/assets/bg-grid.png");
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.3fr;
  gap: 10px 0;
  grid-auto-flow: row;
  grid-template-areas:
    "title title title title title title title"
    "item1 item1 item4 item4 item4 item5 item5"
    "item1 item1 item4 item4 item4 item5 item5"
    "item2 item2 item4 item4 item4 item6 item6"
    "item2 item2 item4 item4 item4 item6 item6"
    "item3 item3 item4 item4 item4 item7 item7"
    "item3 item3 item4 item4 item4 item8 item8"
    "item3 item3 item4 item4 item4 item8 item8"
    "item3 item3 item4 item4 item4 item8 item8";
}

.title {
  grid-area: title;
}

.item1 {
  grid-area: item1;
}

.item2 {
  grid-area: item2;
}

.item3 {
  grid-area: item3;
}

.item4 {
  grid-area: item4;
  display: flex;
  flex-direction: column;
}

.item5 {
  grid-area: item5;
}

.item6 {
  grid-area: item6;
}

.item7 {
  grid-area: item7;
  .item7-header {
    font-size: 14px;
    justify-content: space-between;
    .label {
      margin: 0 10px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .item7-content {
    margin-top: 12px;
    height: 10px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
}

.item8 {
  grid-area: item8;
}
.chartContent {
  width: 100%;
  height: 100%;
}
#item3 {
  .row_item {
    width: 100%;
    display: flex;
    font-size: 12px;
    height: 15%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .r_left {
      // background: url("~@/assets/lv@2x.png") no-repeat center;
      width: 64%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      left: 5%;
      .l_txt {
        margin-top: -3%;
        margin-left: 10%;
      }
      .b_line {
        // background: url("~@/assets/light-lan@2x.png") no-repeat right;
        background-size: cover;
        // position: absolute;
        // bottom: 40%;
        width: 14%;
        height: 92%;
        // right: -8%;
        // bottom: -60%;
      }
    }
    .r_right {
      height: 60%;
      background-size: cover;

      position: relative;
      right: 3%;
      margin-left: 16%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2%;
      padding-right: 4%;
      .r_t_left {
        margin-left: -34%;
      }
    }
    .b_arrow {
      background-size: cover;
      position: absolute;
      bottom: 40%;
      width: 4%;
      height: 28%;
      left: 15%;
      bottom: -10%;
      z-index: 200;
    }

    &.lv {
      .r_left {
        background: url("~@/assets/lv@2x.png") no-repeat center;
        background-size: cover;
      }
      .r_right {
        background: url("~@/assets/box-lv@2x.png") no-repeat center;
        background-size: cover;
        width: 18%;
      }
      .b_arrow {
        background: url("~@/assets/sanjiao-lv@2x.png") no-repeat center;
        background-size: cover;
      }

      .b_line {
        background: url("~@/assets/light-lan@2x.png") no-repeat right;
        background-size: cover;
        position: absolute;
        right: -5%;
        z-index: 100;
        bottom: -50%;
      }
    }
    &.lan {
      .r_left {
        background: url("~@/assets/lan@2x.png") no-repeat center;
        background-size: cover;
      }
      .r_right {
        background: url("~@/assets/box-lan@2x.png") no-repeat center;
        background-size: cover;
        width: 23%;
      }
      .b_arrow {
        background: url("~@/assets/sanjiao-lan@2x.png") no-repeat center;
        background-size: cover;
      }
      .b_line {
        background: url("~@/assets/light-zi@2x.png") no-repeat right;
        background-size: cover;
        position: absolute;
        right: 1%;
        z-index: 100;
        bottom: -60%;
      }
    }
    &.zi {
      .r_left {
        background: url("~@/assets/zi@2x.png") no-repeat center;
        background-size: cover;
      }
      .r_right {
        background: url("~@/assets/box-zi@2x.png") no-repeat center;
        background-size: cover;
        width: 28%;
      }
      .b_arrow {
        background: url("~@/assets/sanjiao-zi@2x.png") no-repeat center;
        background-size: cover;
      }
      .b_line {
        background: url("~@/assets/light-cheng@2x.png") no-repeat right;
        background-size: cover;
        position: absolute;
        right: 8%;
        z-index: 100;
        bottom: -70%;
      }
    }
    &.cheng {
      .r_left {
        background: url("~@/assets/cheng@2x.png") no-repeat center;
        background-size: cover;
      }
      .r_right {
        background: url("~@/assets/box-cheng@2x.png") no-repeat center;
        background-size: cover;
        width: 33%;
      }
      .b_arrow {
        background: url("~@/assets/sanjiao-cheng@2x.png") no-repeat center;
        background-size: cover;
      }
      .b_line {
        background: url("~@/assets/light-huang@2x.png") no-repeat right;
        background-size: cover;
        position: absolute;
        right: 15%;
        z-index: 100;
        bottom: -70%;
      }
    }
    &.huang {
      .r_left {
        background: url("~@/assets/huang@2x.png") no-repeat center;
        background-size: cover;
      }
      .r_right {
        background: url("~@/assets/box-huang@2x.png") no-repeat center;
        background-size: cover;
        width: 38%;
      }
      .b_arrow {
        background: url("~@/assets/sanjiao-huang@2x.png") no-repeat center;
        width: 4%;
        height: 20%;
        margin-top: -100%;
      }
      .b_line {
        background: url("~@/assets/light-hong@2x.png") no-repeat right;
        background-size: cover;
        position: absolute;
        right: 23%;
        z-index: 100;
        bottom: -75%;
      }
    }
    &.hong {
      .r_left {
        width: 64%;
        background: url("~@/assets/hong@2x.png") no-repeat center;
        background-size: cover;
      }
      .r_right {
        background: url("~@/assets/box-hong@2x.png") no-repeat center;
        background-size: cover;
        width: 43%;
      }
      .b_arrow {
        // background: url("~@/assets/sanjiao-hong@2x.png") no-repeat center;
      }
      .b_line {
        // background: url("~@/assets/light-hong@2x.png") no-repeat right;
      }
    }
  }
}
.item4-1 {
  height: 25%;
  width: 100%;
  padding: 5px;
}
.item4-2 {
  width: 100%;
  flex: 1;
}
</style>
