<template>
  <div @mousewheel="scrollFunc" class="index-page">
    <transition>
      <vHome v-if="currentPage === 'index'" @changePage="changePage"></vHome>
      <vPinPai
        v-if="currentPage === 'pinPai'"
        @changePage="changePage"
      ></vPinPai>
      <vWuLiu v-if="currentPage === 'wuLiu'" @changePage="changePage"></vWuLiu>
      <vZhiBo v-if="currentPage === 'zhiBo'" @changePage="changePage"></vZhiBo>
    </transition>
  </div>
</template>

<script>
import vHome from "./home";
import vPinPai from "./pinpai";
import vWuLiu from "./wuliu";
import vZhiBo from "./zhibo";
import _ from "lodash";
export default {
  name: "systemIndex",
  components: { vHome, vPinPai, vWuLiu, vZhiBo },
  data() {
    return {
      currentPage: "index",
      pageList: ["index", "zhiBo", "wuLiu", "pinPai"],
      currentPageIndex: 0,
      zhiboData: {},
    };
  },
  created() {
    this.$axios("https://mock.apifox.cn/m1/2727236-0-default/live").then(
      (res) => {
        this.zhiboData = res.data;
      }
    );
  },
  methods: {
    changePage(code) {
      this.currentPage = code;
    },
    scrollFunc: _.debounce(function (e) {
      const elem = e.target;
      const zhiboContent = document.getElementById("zhiBoContent");
      if (!this.zhiboData.disabledScroll) {
        if (zhiboContent && zhiboContent.contains(elem)) {
          return false;
        }
      }
      if (e.wheelDelta > 0) {
        if (this.currentPageIndex === 0) {
          return false;
        }
        this.currentPageIndex--;
      } else {
        if (this.currentPageIndex === 3) {
          return false;
        }
        this.currentPageIndex++;
      }
      this.currentPage = this.pageList[this.currentPageIndex];
    }, 250),
  },
};
</script>

<style scoped lang="scss">
.index-page {
  background: #000;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0.5;
}
</style>
